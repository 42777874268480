// import { MEDIA_CONFIG } from "common";
import usePage from "hooks/usePage";
// import bot from "images/bot.png";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import PageFade from "ui/PageFade";
import SecurityRobot from "images/robot.png";
import Modal from "ui/UltraModal";
import classNames from "classnames";
// import { useMediaQueries } from "@react-hook/media-query";

// import DesktopDonate from "./Donate.desktop";
// import MobileDonate from "./Donate.mobile";

// const Donate: FC = () => {
// 	const { matches } = useMediaQueries(MEDIA_CONFIG);

// 	const Widget = matches.isMobile ? MobileDonate : DesktopDonate;

// 	return (
// 		<PageFade>
// 			<Widget />
// 		</PageFade>
// 	);
// };

const Donate: FC = () => {
	const history = useHistory();
	const excess = usePage("excess");

	return (
		<PageFade>
			<div className="w-screen h-screen flex relative px-6 md:px-0">
				<Modal
					isCentered
					isOpen
					className="mx-6 md:mx-0"
					onClose={() => {
						history.goBack();
					}}
					title={
						<div className="uppercase">
							<div>SECURITY PROMPT</div>
							COMING SOON
						</div>
					}
				>
					<div className="p-4">
						<div className="pt-8 md:pt-6 pb-8 flex justify-center">
							<img
								alt="security robot"
								src={SecurityRobot}
								className={classNames("w-32 h-32 md:w-28 md:h-28 h-auto object-contain p")}
							/>
						</div>
						<div className="pb-10 md:pb-6 text-center">
							<div dangerouslySetInnerHTML={{ __html: excess?.body }} />
						</div>
					</div>
				</Modal>
			</div>
		</PageFade>
	);
};

export default Donate;

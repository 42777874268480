import { GuestbookEntry, MEDIA_CONFIG } from "common";
import useGuestbook from "hooks/useGuestbook";
import html2canvas from "html2canvas";
import sleeve from "images/sleeve.png";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import ReactDOMServer from "react-dom/server";
import { Fader } from "ui/Fader";
import IDCard from "ui/IDCard";
import IDInput from "ui/IDInput";
import Modal from "ui/UltraModal";
import styles from "./Guestbook.module.css";

import { useMediaQueries } from "@react-hook/media-query";
import classNames from "classnames";
import { removeDuplicateObjectsFromArray } from "utils/removeDuplicateObjectsFromArray";

type GuestbookProps = {
	profoundWords?: string;
};

const Guestbook: FC<GuestbookProps> = ({ profoundWords }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const stage = useRef<HTMLDivElement>(null);
	const map = useRef<google.maps.Map>();
	const info = useRef<google.maps.InfoWindow>();
	const scrollRef = useRef<HTMLDivElement>(null);
	const cardRef = useRef<any>(null);
	const listRef = useRef<HTMLDivElement>(null);

	const [step, setStep] = useState(1);
	const [isMapView, setIsMapView] = useState(true);
	const [savedEntry, setSavedEntry] = useState<GuestbookEntry>();
	const [listEntry, setListEntry] = useState<GuestbookEntry>();
	const [isListCardOpen, setIsListCardOpen] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [downloadLink, setDownloadLink] = useState("");

	const { entries, refresh } = useGuestbook();
	const _entries = useMemo(() => removeDuplicateObjectsFromArray(entries), [entries]);

	const openMapCard = useCallback(
		(entry: GuestbookEntry, marker: google.maps.Marker) => {
			resetEntry();
			if (!info.current) return;
			const content = ReactDOMServer.renderToString(
				<div className="text-center">
					<div style={{ width: 512, height: 278 }} className="ml-2">
						<IDCard
							value={entry}
							className="shadow-xl"
							style={{ transform: "scale(0.5)", transformOrigin: "left top" }}
						/>
					</div>
					{savedEntry && (
						<div className="w-full text-center mt-6" data-trigger="id-card">
							{matches.isTablet ? (
								<a
									href={downloadLink}
									download
									target="_blank"
									className="pill glass-pane ml-3"
									data-print-png
									rel="noreferrer"
								>
									DOWNLOAD
								</a>
							) : (
								<a
									href={downloadLink}
									download
									target="_blank"
									className="pill glass-pane ml-3"
									data-print-png
									rel="noreferrer"
								>
									DOWNLOAD
								</a>
							)}
						</div>
					)}
				</div>
			);
			info.current.setContent(content);
			info.current?.open({
				anchor: marker,
				map: map.current,
				shouldFocus: false,
			});
		},
		[savedEntry, downloadLink, matches.isTablet]
	);

	const scrollBetweenViews = () => {
		if (isMapView) {
			listRef.current.scrollIntoView({
				behavior: "smooth",
			});
			return;
		}
		scrollRef.current.scrollIntoView({
			behavior: "smooth",
		});
	};

	const resetEntry = () => {
		setStep(1);
	};

	const startEntry = () => {
		closeListCard();
		info.current?.close();
		setStep(2);
	};

	const finishEntry = async (entry: GuestbookEntry) => {
		await refresh();
		setSavedEntry(entry);
		setIsComplete(true);
	};

	const openListCard = (entry: GuestbookEntry) => {
		resetEntry();
		setListEntry(entry);
		setIsListCardOpen(true);
	};

	const closeListCard = () => {
		setIsListCardOpen(false);
	};

	// Listen globally for PNG and IG print triggers
	useEffect(() => {
		const onClick = (event: MouseEvent) => {
			const isPrintPNG = (event.target as HTMLBodyElement).closest("[data-print-png]");

			if (isPrintPNG) {
				exportComponentAsPNG(cardRef, {
					fileName: `${savedEntry?.name}-hpOS-ID`,
					html2CanvasOptions: { backgroundColor: null },
				});
			}
		};
		document.body.addEventListener("click", onClick);
		return () => document.body.removeEventListener("click", onClick);
	}, [savedEntry?.name]);

	// Create map and info window
	useEffect(() => {
		if (!stage.current) return;
		map.current = new google.maps.Map(stage.current, {
			center: new google.maps.LatLng(40.7128, -40.006),
			disableDefaultUI: true,
			styles: [
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [{ visibility: "off" }],
				},
			],
			zoom: 3,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT,
			},
		});
		info.current = new google.maps.InfoWindow({ content: "" });
		map.current.addListener("click", () => {
			info.current?.close();
			setSavedEntry(undefined);
		});
	}, []);

	// Populate map with markers
	useEffect(() => {
		if (!map.current) return;
		_entries?.forEach((entry) => {
			const htmlMarker = new google.maps.Marker({
				icon: { url: entry.photo, scaledSize: new google.maps.Size(32, 32) },
				map: map.current,
				optimized: true,
				position: { lat: Number(entry.lat), lng: Number(entry.lng) },
			});
			htmlMarker.addListener("click", () => openMapCard(entry, htmlMarker));
			if (savedEntry && savedEntry.led === entry.led) {
				openMapCard(entry, htmlMarker);
			}
		});
	}, [_entries, savedEntry, openMapCard]);

	useEffect(() => {
		const ref = scrollRef.current;

		const int = new IntersectionObserver((entries) => {
			const intEntry = entries[0];
			setIsMapView(intEntry.isIntersecting && intEntry.intersectionRatio > 0);
		});

		int.observe(ref);

		return () => {
			int.unobserve(ref);
			int.disconnect();
		};
	}, []);

	// Cache tablet download link
	useEffect(() => {
		async function cacheImage() {
			if (!cardRef.current) return;
			const canvas = await html2canvas(cardRef.current);
			canvas.toBlob((blob) => {
				const href = URL.createObjectURL(blob as Blob);
				setDownloadLink(href);
			});
		}
		cacheImage();
	}, [savedEntry]);

	return (
		<div className="w-full h-screen overflow-hidden">
			<div className="w-full h-screen relative px-8 lg:px-20 pt-24 lg:pt-40 pb-56 mb-1" ref={scrollRef}>
				<div className="w-full h-full border border-black" ref={stage}></div>
				<button
					className="font-header-2 fixed left-1/2 bottom-6 lg:bottom-16 transform -translate-x-1/2 text-center cursor-pointer z-50"
					onClick={scrollBetweenViews}
				>
					{isMapView ? (
						<div className="pill glass-pane">LIST VIEW</div>
					) : (
						<div className="pill glass-pane">MAP VIEW</div>
					)}
				</button>

				{savedEntry && (
					<div className="fixed p-10 bg-black top-0 fixed" style={{ left: -100000 }} ref={cardRef}>
						<img
							fetchpriority="high"
							alt="sleeve"
							className="relative z-20"
							src={sleeve}
							style={{ width: 1112, height: "auto", maxWidth: "none" }}
							loading="eager"
						/>
						<IDCard value={savedEntry} style={{ position: "absolute", top: 58, left: 58 }} />
					</div>
				)}
			</div>

			<div className="w-screen h-screen relative px-8 lg:px-20 pt-20 lg:pt-32 pb-56" ref={listRef}>
				<div className="flex items-stretch odd:">
					<div className="flex-none w-24 relative flex-none flex items-center text-center justify-center font-header-2 text-transparent desktop-hover:hover:text-black">
						<div className="underline absolute w-full h-full flex justify-center flex-col">View ID</div>
					</div>
					<div className="flex lg:flex-none flex-auto">
						<div className="w-72 flex-none  px-4 py-2">
							<div className="w-full h-full line-clamp-2 break-words font-header-2">NAME</div>
						</div>
						<div className="lg:w-72 lg:flex-none flex-auto lg: px-4 py-2 font-header-2">PLACE + TIME</div>
					</div>
					<div className="flex-auto px-4 py-2 hidden lg:block min-w-0">
						<div className="w-full h-full line-clamp-2 break-words font-header-2">CONTACT INFO</div>
					</div>
				</div>

				<div className="border border-black h-full overflow-y-auto ">
					{_entries?.map((item, i) => {
						return (
							<div
								className="border-b last:border-b-0 border-black flex items-stretch odd:bg-gray-1"
								key={`${item.lat}-${item.lng}-${item.led}-${item.name}`}
							>
								<div
									className="flex-none w-24 relative flex-none border-r border-black p-4 bg-cover cursor-pointer flex items-center text-center justify-center font-header-2 text-transparent desktop-hover:hover:text-black"
									style={{ backgroundImage: `url(${item.photo})` }}
									onClick={() => openListCard(item)}
									data-trigger="id-card"
								>
									<div className="underline absolute w-full h-full desktop-hover:hover:bg-white/50 flex justify-center flex-col">
										View ID
									</div>
								</div>
								<div className="flex lg:flex-none flex-auto">
									<div className="w-72 flex-none border-r border-black p-4">
										<div
											className="w-full h-full line-clamp-2 break-words cursor-pointer desktop-hover:hover:underline"
											onClick={() => openListCard(item)}
										>
											{item.name}
										</div>
									</div>
									<div className="lg:w-72 lg:flex-none flex-auto border-r-0 lg:border-r border-black p-4">
										<div className="whitespace-nowrap truncate">{item.place}</div>
										<div className="whitespace-nowrap truncate">{moment(item.time).format("MM.DD.YYYY")}</div>
									</div>
								</div>
								<div className="flex-auto p-4 hidden lg:block min-w-0">
									<div className="w-full h-full line-clamp-2 break-words">{item.msg}</div>
								</div>
							</div>
						);
					})}
				</div>

				{step < 3 && !isComplete && (
					<Modal
						isOpen
						useGlass={true}
						title="ID CARD GENERATOR"
						containerClassName={classNames(
							styles["disable-transition"],
							"fixed left-1/2 transform -translate-x-1/2 bottom-32"
						)}
						onClose={step > 1 ? resetEntry : undefined}
					>
						<div className="p-4">
							{step === 1 && (
								<>
									Sign the guestbook by creating your own L.E.D. Studio ID Card.
									<button className="btn mt-4 w-full" onClick={startEntry}>
										SIGN GUESTBOOK
									</button>
								</>
							)}
							{step === 2 && <IDInput onSuccess={finishEntry} key={savedEntry?.led} profoundWords={profoundWords} />}
						</div>
					</Modal>
				)}

				<Fader isVisible={isListCardOpen} className="z-10 w-full h-full absolute left-0 top-0">
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
						<div className="block m-auto">
							<IDCard
								className="shadow-xl"
								onClose={closeListCard}
								style={{ transform: "scale(0.5)" }}
								value={listEntry}
							/>
						</div>
					</div>
				</Fader>
			</div>
		</div>
	);
};

export default Guestbook;

import React, { ReactNode } from "react";
import { useLocation } from "react-router";

import { Transition } from "@tailwindui/react";

function PageFade({ children, key, appear }: { children: ReactNode; key?: string; appear?: boolean }) {
	const location = useLocation();

	return (
		<Transition
			show
			appear={appear}
			enter="transition-opacity duration-2000"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-2000"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
			className="h-full w-full flex flex-col"
			key={key || location.key}
		>
			{children}
		</Transition>
	);
}

export default PageFade;

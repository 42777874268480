import { Routes } from "common";

import useCart, { CartState, Checkout } from "hooks/useCart";
import { FC, createContext, useEffect, useMemo, useState } from "react";
import { Route, BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import Chrome from "ui/Chrome";
import { Fader } from "ui/Fader";

import {
	GetAllLinksQuery,
	GetHomePageQuery,
	GetMenuQuery,
	GetProjectsQuery,
	GetSettingsQuery,
} from "lib/generated/sdk";
import { PageNotAvailableModal } from "ui/PageNotAvailableModal";
import checkIsExternal from "utils/isExternal";
import { nextClient } from "../lib/graphql/client";
import NotFound from "./404";
import Donate from "./Donate";
import ExRay from "./ExRay";
import Guestbook from "./Guestbook";
import Home from "./Home";
import Product from "./Product";
import Project from "./Project";
import Projects from "./Projects";
import ServiceCenter from "./ServiceCenter";
import Shop from "./Shop";
import Watch from "./Watch";
import Fund from "./Fund";

export const CartContext = createContext<[CartState, Checkout]>([] as any);

interface Data {
	homepage: GetHomePageQuery;
	menu: GetMenuQuery;
	settings: GetSettingsQuery;
	projects: GetProjectsQuery;
	allLinks: GetAllLinksQuery;
}

const AppShell: FC = () => {
	const [isReady, setIsReady] = useState(false);
	const [data, setData] = useState<null | Data>(null);
	const [appearOffline, setAppearOffline] = useState<boolean>(false);
	const cart = useCart();

	useEffect(() => {
		const fakeLoad = new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 1750);
		});

		const homepage = nextClient.getHomePage();
		const menu = nextClient.getMenu();
		const settings = nextClient.getSettings();
		const projects = nextClient.getProjects();
		const allLinks = nextClient.getAllLinks();

		Promise.allSettled([fakeLoad, homepage, menu, settings, projects, allLinks]).then(([_, ...rest]) => {
			let dataValues = [];
			for (let i = 0; i < rest.length; i++) {
				dataValues.push(rest[i].status === "fulfilled" ? (rest[i] as PromiseFulfilledResult<unknown>).value : null);
			}

			const props: Data = {
				homepage: dataValues[0] as GetHomePageQuery,
				menu: dataValues[1] as GetMenuQuery,
				settings: dataValues[2] as GetSettingsQuery,
				projects: dataValues[3] as GetProjectsQuery,
				allLinks: dataValues[4] as GetAllLinksQuery,
			};

			const websiteIsLive = props.settings.globalSetting.websiteIsLive;

			setData(props);
			setIsReady(true);
			setAppearOffline(!websiteIsLive);
		});
	}, []);

	return (
		// Fades entire page slowly on page load
		<Fader appear isVisible className="h-full w-full">
			<CartContext.Provider value={cart}>
				<Router>
					<Route path="/:page?">
						<Chrome
							about={data?.settings?.globalSetting?.about}
							menu={data?.menu}
							isLoading={!isReady}
							appearOffline={appearOffline}
							offlineMessage={data?.settings?.globalSetting?.offlineMessage}
							terms={data?.settings?.globalSetting?.termsOfService}
						>
							<App data={data} visible={isReady} appearOffline={false} />
						</Chrome>
					</Route>
				</Router>
			</CartContext.Provider>
		</Fader>
	);
};

interface AppProps {
	visible?: boolean;
	data?: Data;
	appearOffline: boolean;
}

const App: FC<AppProps> = ({ visible, data, appearOffline = false }) => {
	const location = useLocation();

	const availablePages = useMemo(() => {
		if (data?.menu?.navigation) {
			const { menu } = { ...data.menu.navigation };

			return Object.fromEntries(
				menu.map((el) => [
					el.title.toLowerCase(),
					{ pageIsAvailable: el.pageIsAvailable, pageIsUnavailableMessage: el.pageIsUnavailableMessage },
				])
			);
		}
	}, [data]);

	const restPages = useMemo(() => {
		if (data?.menu?.navigation) {
			const links = data.allLinks.allMenuItems;

			const menuWithoutSocials = links.filter(({ slug, pageIsAvailable }) => {
				const isExternal = checkIsExternal(slug);
				return !pageIsAvailable && !isExternal;
			});

			return menuWithoutSocials
				.filter(({ slug }) => !Object.values(Routes).includes(slug as Routes))
				.map((el) => ({
					id: el.id,
					slug: el.slug,
					pageIsAvailable: el.pageIsAvailable,
					pageIsUnavailableMessage: el.pageIsUnavailableMessage,
				}));
		}
	}, [data]);

	return data && !appearOffline ? (
		<>
			<Switch location={location}>
				<Route path={Routes.Home} exact>
					<Home data={data.homepage} />
				</Route>
				<Route path={Routes.Shop} exact>
					{availablePages?.["shop"] && availablePages?.["shop"]?.pageIsAvailable ? (
						<Shop />
					) : (
						<PageNotAvailableModal {...availablePages?.["shop"]} />
					)}
				</Route>
				<Route path={Routes.ExRay} exact>
					<ExRay />
				</Route>
				<Route path={Routes.Donate} exact>
					{availablePages?.["excess"] && availablePages?.["excess"]?.pageIsAvailable ? (
						<Donate />
					) : (
						<PageNotAvailableModal {...availablePages?.["excess"]} />
					)}
				</Route>
				<Route path={Routes.Guestbook} exact>
					{availablePages?.["world"] && availablePages?.["world"]?.pageIsAvailable ? (
						<Guestbook profoundWords={data.settings.globalSetting.profoundWords} />
					) : (
						<PageNotAvailableModal {...availablePages?.["world"]} />
					)}
				</Route>
				<Route path={`${Routes.Product}/:handle`} exact>
					{availablePages?.["shop"] && availablePages?.["shop"]?.pageIsAvailable ? (
						<Product visible={visible} />
					) : (
						<PageNotAvailableModal {...availablePages?.["shop"]} />
					)}
				</Route>
				<Route path={`${Routes.Watch}/:videoId?`}>
					{availablePages?.["watch"] && availablePages?.["watch"]?.pageIsAvailable ? (
						<Watch />
					) : (
						<PageNotAvailableModal {...availablePages?.["watch"]} />
					)}
				</Route>
				<Route path={Routes.ServiceCenter} exact>
					{availablePages?.["service center"] && availablePages?.["service center"]?.pageIsAvailable ? (
						<ServiceCenter data={data.settings.globalSetting.serviceCenter} />
					) : (
						<PageNotAvailableModal {...availablePages?.["service center"]} />
					)}
				</Route>
				<Route path={Routes.Projects} exact>
					{availablePages?.["service center"] && availablePages?.["service center"]?.pageIsAvailable ? (
						<Projects data={data.projects} />
					) : (
						<PageNotAvailableModal {...availablePages?.["service center"]} />
					)}
				</Route>
				<Route path={`${Routes.Projects}/:handle`} exact>
					{availablePages?.["service center"] && availablePages?.["service center"]?.pageIsAvailable ? (
						<Project data={data.projects} />
					) : (
						<PageNotAvailableModal {...availablePages?.["service center"]} />
					)}
				</Route>
				<Route path={Routes.Fund} exact>
					{availablePages?.["fund"] && availablePages?.["fund"]?.pageIsAvailable ? (
						<Fund data={{ ...data.settings }} />
					) : (
						<PageNotAvailableModal {...availablePages?.["fund"]} />
					)}
				</Route>

				{restPages.map((route) => (
					<Route key={route.id} path={route.slug} exact>
						<PageNotAvailableModal {...route} />
					</Route>
				))}

				<Route component={NotFound} />
			</Switch>
		</>
	) : null;
};

export default AppShell;

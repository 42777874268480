import { useEffect } from "react";

const useBgClick = (trigger: string, cb: (event: MouseEvent) => void) => {
	useEffect(() => {
		const handler = (event: MouseEvent) => {
			const target = (event.target as HTMLBodyElement).closest("[data-trigger]");
			if (!target || target.getAttribute("data-trigger") !== trigger) {
				cb(event);
			}
		};
		document.body.addEventListener("click", handler);
		return () => document.body.removeEventListener("click", handler);
	}, [cb, trigger]);
};

export default useBgClick;

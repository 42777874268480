import classNames from "classnames";
import { FC } from "react";
import PageFade from "ui/PageFade";
import Modal from "ui/UltraModal";
import SecurityRobot from "images/robot.png";

const NotFound: FC = () => {
	return (
		<PageFade>
			<div className="w-screen h-screen flex relative px-6 md:px-0">
				<Modal
					isCentered
					isOpen
					className="mx-6 md:mx-0"
					title={
						<div className="uppercase">
							<div>SECURITY PROMPT</div>
							404 ERROR
						</div>
					}
				>
					<div className="p-4">
						<div className="pt-8 md:pt-6 pb-8 flex justify-center">
							<img
								alt="security robot"
								src={SecurityRobot}
								className={classNames("w-32 h-32 md:w-28 md:h-28 h-auto object-contain p")}
							/>
						</div>
						<div className="pb-10 md:pb-6 text-center">404 - The page you are looking for doesn’t exist.</div>
					</div>
				</Modal>
			</div>
		</PageFade>
	);
};

export default NotFound;

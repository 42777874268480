import { Routes } from "common";
import moment from "moment";
import { FC, memo, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Fader } from "./Fader";

export interface TimeProps {
	isLoading?: boolean;
}

const Time: FC<TimeProps> = memo(({ isLoading }) => {
	const params = useParams<{ page: string }>();

	return (
		<Fader isVisible={!isLoading}>
			<div className="text-right uppercase font-header-2 fixed z-50 right-8 top-6 lg:right-20 lg:top-16 hidden md:inline-block">
				<Link className="block" to={Routes.Guestbook}>
					<Clock />
				</Link>
				<div className="text-blue-1">viewing: {params.page?.replace(/-/g, " ") || "Home"}</div>
			</div>
		</Fader>
	);
});

const Clock = () => {
	const [time, setTime] = useState("Locating...");
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/_/g, " ").replace(/\//g, " / ");

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(moment().format("HH:mm:ss"));
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			<span className="inline-block">{time}</span> / {timezone}
		</>
	);
};

export default Time;

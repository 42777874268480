import useSWR from "swr";

import fetcher from "./fetcher";

const useLiveStream = () => {
	const { data } = useSWR<{ liveUrl: string }>(`/api/liveStream`, fetcher, {
		refreshInterval: 20000,
		shouldRetryOnError: false,
	});

	return data?.liveUrl;
};

export default useLiveStream;

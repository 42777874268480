import classNames from "classnames";
import { Routes } from "common";
import { FC, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "./Project.module.css";
import { GetProjectsQuery } from "lib/generated/sdk";
import { render } from "datocms-structured-text-to-plain-text";
import { StructuredText } from "react-datocms/structured-text";
import { Img } from "pages/Project.desktop";

const MAX_LENGTH = 408;

const Project: FC<{ data: GetProjectsQuery }> = ({ data }) => {
	const params = useParams<{ handle: string }>();
	const project = data?.allProjects?.find((proj) => proj.link === params.handle);

	const [viewMore, setViewMore] = useState(false);
	const txtString = render(project.excerpt.value);
	const txtLength = txtString.length;

	const hasMore = txtLength > MAX_LENGTH;
	const shortText = hasMore ? `${txtString.slice(0, MAX_LENGTH)}...` : txtString;

	const orderedImages = useMemo<Array<Img | Img[]>>(() => {
		const resultArray = [];
		let group = [];

		for (const item of project.projectImages) {
			if (item.orientation) {
				//Horizontal
				resultArray.push(item);
			} else {
				group.push(item);
				if (group.length === 2) {
					resultArray.push(group);
					group = [];
				}
			}
		}

		if (group.length && group.length < 2) {
			resultArray.push(group);
		}

		return resultArray;
	}, [project.projectImages]);

	return (
		<div className={classNames("h-full w-full flex flex-col", styles.root)}>
			<div className="w-full h-full pt-20">
				<div className="w-full h-full overflow-auto border-t border-black p-6">
					<img src={project.thumbnail.url} alt="featured" className="mb-6" loading="lazy" />
					<div className="font-header-2 mb-2">{project.title}</div>
					<div className="font-body mb-6">{viewMore ? <StructuredText data={project.excerpt.value} /> : shortText}</div>
					{hasMore && (
						<button className="mb-6 font-header-2" onClick={() => setViewMore(!viewMore)}>
							{viewMore ? <>- VIEW LESS</> : <>+ VIEW MORE</>}
						</button>
					)}
					<div className="studio-c-content">
						{orderedImages.map((image) => {
							return Array.isArray(image) ? (
								<p key={image[0].projectImage.url}>
									<img src={image[0].projectImage.url} alt="" />
									{image.length > 1 ? <img src={image[1].projectImage.url} alt="" /> : null}
								</p>
							) : (
								<p key={image.projectImage.url}>
									<img src={image.projectImage.url} alt="" />
								</p>
							);
						})}
					</div>
				</div>
			</div>
			<div className="fixed left-1/2 bottom-16 transform -translate-x-1/2 z-50">
				<Link
					to={{ pathname: Routes.Projects, state: { skipTransition: true } }}
					className="font-header-2 cursor-pointer pill glass-pane"
				>
					BACK TO PROJECTS
				</Link>
			</div>
		</div>
	);
};

export default Project;

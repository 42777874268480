import { MEDIA_CONFIG, Routes } from "common";
import { FC, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import PageFade from "ui/PageFade";

import { useMediaQueries } from "@react-hook/media-query";
import { GetProjectsQuery } from "lib/generated/sdk";

interface ProjectsProps {
	data: GetProjectsQuery;
}

const Projects: FC<ProjectsProps> = ({ data }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const location = useLocation<{ skipTransition?: boolean }>();

	useEffect(() => {
		window.history.replaceState({}, document.title);
	}, []);

	return (
		<PageFade appear={!location.state?.skipTransition}>
			{!matches.isMobile ? (
				<div className="w-full h-full projects flex-wrap flex content-start lg:py-44 md:py-20 md:pb-32">
					{data.allProjects.map((project) => {
						return (
							<div className="proj flex items-center justify-center py-16">
								<Link
									to={`${Routes.Projects}/${project.link}`}
									className="border inline-block border-black text-black transform hover:scale-105 transition-all duration-750"
								>
									<div className="border-b px-2 py-3 font-body border-current truncate">
										{project.title.toUpperCase()}
									</div>
									<div className="p-2">
										<img
											className="object-cover bg-cover"
											src={project.thumbnail.url}
											alt={project.title}
											style={{ width: matches.isDesktop ? 295 : 150, height: matches.isDesktop ? 188 : 105 }}
										/>
									</div>
								</Link>
							</div>
						);
					})}
				</div>
			) : (
				<div className="w-full h-full overflow-y-auto grid grid-cols-2 gap-4 pt-24 px-6 pb-40">
					{data.allProjects.map((project) => {
						return (
							<Link
								to={{ pathname: `${Routes.Projects}/${project.link}`, state: { skipTransition: true } }}
								className="border inline-block border-black text-black"
							>
								<div className="border-b px-2 py-3 font-body border-current truncate">
									{project.title.toUpperCase()}
								</div>
								<div className="p-2">
									<img src={project.thumbnail.url} alt={project.title} />
								</div>
							</Link>
						);
					})}
				</div>
			)}
			<div className="fixed left-1/2 bottom-16 transform -translate-x-1/2 z-50">
				<Link to={Routes.ServiceCenter} className="font-header-2 cursor-pointer pill glass-pane">
					BACK TO SERVICE CENTER
				</Link>
			</div>
		</PageFade>
	);
};

export default Projects;

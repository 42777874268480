import classNames from "classnames";
import { Routes } from "common";
import { FC } from "react";
import { useHistory } from "react-router-dom";

import styles from "./Fund.module.css";
import { GlobalSettingModelFundDescriptionField } from "lib/generated/sdk";
import { StructuredText } from "react-datocms/structured-text";

import ReactPlayer from "react-player";

const Fund: FC<{ title: string; description: GlobalSettingModelFundDescriptionField; videoUrl: string }> = ({
	title,
	description,
	videoUrl,
}) => {
	const history = useHistory();

	return (
		<div className={classNames("h-full w-full flex flex-col", styles.root)}>
			<div className="border border-black flex flex-col overflow-hidden fixed lg:top-48 md:bottom-40 lg:left-20 lg:right-20 md:top-36 md:left-8 md:right-8">
				<div className="flex justify-between items-center border-b border-black p-4 font-header-2 uppercase">
					<div>{title}</div>
					<button onClick={() => history.push(Routes.Home, { skipTransition: true })}>- COLLAPSE</button>
				</div>
				<div className="flex-auto flex min-h-0">
					<div className="w-1/3 lg:w-1/4 px-4 pt-4 overflow-y-auto pb-4 flex-none">
						<div className={classNames("font-body", styles.content)}>
							<StructuredText data={description.value} />
						</div>
						<div className="text-center my-8 transform z-50 ">
							<a href="mailto:initiative@ledstudio.com" className="font-header-2 cursor-pointer pill glass-pane">
								APPLY
							</a>
						</div>
					</div>
					<div className="flex-auto p-4 border-l border-black overflow-y-auto min-h-0 studio-c-content">
						<ReactPlayer
							controls={true}
							url={videoUrl}
							width="auto"
							height="auto"
							style={{
								aspectRatio: "16/9",
								height: "auto",
								maxHeight: "100%",
								margin: "0 auto",
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Fund;

import { useEffect, useState } from "react";

const usePage = (handle: string) => {
	const [page, setPage] = useState<any>();

	const QUERY = `
        {
            page(handle: "${handle}") {
                body
            }
        }
    `;

	useEffect(() => {
		const query = async () => {
			const response = await fetch(`https://${process.env.REACT_APP_SHOPIFY_URL}/api/2021-10/graphql.json`, {
				method: "POST",
				headers: {
					"Content-Type": "application/graphql",
					"X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_TOKEN || "",
				},
				body: QUERY,
			}).then((response) => response.json());

			setPage(response.data.page);
		};
		query();
	}, [QUERY]);

	return page;
};

export default usePage;

import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";

import { Fader } from "./Fader";

export interface ModalProps {
	className?: string;
	isCentered?: boolean;
	useGlass?: boolean;
	containerClassName?: string;
	isOpen?: boolean;
	onClose?: () => void;
	style?: CSSProperties;
	title?: ReactNode;
	isSimple?: boolean;
	removeChildren?: boolean;
	children: ReactNode;
}

function Modal({
	isCentered,
	useGlass = true,
	containerClassName,
	children,
	className,
	isOpen,
	onClose,
	style,
	title,
	isSimple,
	removeChildren = true,
}: ModalProps) {
	const displayTitle =
		typeof title === "string" ? (
			<div className="flex items-end">
				<div>
					<div className="justify-between flex">
						<div>L.E.D.</div>
						<div>STUDIO</div>
					</div>
					HP oper.SYSTEM v1.1
				</div>
				<div>&nbsp;{title}</div>
			</div>
		) : (
			title
		);

	const content = (
		<div
			className={classNames("border border-black flex flex-col items-stretch select-none", className)}
			style={{ width: 530, maxHeight: "68vh", maxWidth: "calc(100vw  - 48px)", ...style }}
		>
			<div className="px-4 py-3 font-header-2 border-b border-black flex items-center border-box">
				<div className="flex flex-grow flex-shrink h-8 items-center">{displayTitle}</div>
				{onClose && (
					<button className="flex-none text-lg" onClick={onClose}>
						✕
					</button>
				)}
			</div>
			<div className="flex-auto overflow-y-auto overflow-x-hidden">{children}</div>
		</div>
	);

	return isSimple ? (
		<>{isOpen ? content : null}</>
	) : (
		<Fader
			isVisible={isOpen}
			duration={500}
			className={classNames({
				"glass-pane": useGlass,
				[containerClassName]: !!containerClassName,
				"fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50": isCentered,
			})}
			removeChildren={removeChildren}
		>
			{content}
		</Fader>
	);
}

export default Modal;

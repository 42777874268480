import classNames from "classnames";
import { MEDIA_CONFIG } from "common";
import useBgClick from "hooks/useBgClick";
import useCartMenu from "hooks/useCartMenu";
import useChat from "hooks/useChat";
import useMenu from "hooks/useMenu";
import glow from "images/glow.png";
import logoText from "images/logo-text.svg";
import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import { useMediaQueries } from "@react-hook/media-query";

import useAbout from "hooks/useAbout";
import { Fader } from "./Fader";
import styles from "./NavLogo.module.css";

interface NavLogoProps {
	appearOffline?: boolean;
	isLoading?: boolean;
}

const TRACK_CURSOR = false;

const NavLogo: FC<NavLogoProps> = ({ isLoading, appearOffline = false }) => {
	const location = useLocation();
	const lastLocation = useRef<string>();
	const textRef = useRef<HTMLDivElement>(null);
	const rootRef = useRef<HTMLImageElement>(null);
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const [isMenuOpen, setIsMenuOpen] = useMenu(false);
	const [isChatOpen] = useChat(false);
	const [, setIsAboutOpen] = useAbout(false);

	const [, setIsCartOpen] = useCartMenu(false);
	const [logoCoords, setLogoCoords] = useState<{ x: number; y: number }>();

	const isMobileChatOpen = matches.isMobile && !!isChatOpen;
	const isLogoBig = isMenuOpen || isMobileChatOpen;
	const left = logoCoords?.x ? logoCoords.x : undefined;
	const top = logoCoords?.y ? logoCoords.y : undefined;

	const closeChat = useCallback(() => {
		if (isChatOpen) {
			(document.querySelector("#sitegpt-chat-icon") as HTMLButtonElement).click();
		}
	}, [isChatOpen]);

	const toggleMenu = useCallback(() => {
		if (isLoading || appearOffline) return;
		if (isMenuOpen === false) {
			setIsCartOpen(false);
			setIsAboutOpen(false);
			closeChat();
		}
		setIsCartOpen(false);
		setLogoCoords(undefined);
		setIsMenuOpen(!isMenuOpen);
	}, [appearOffline, closeChat, isLoading, isMenuOpen, setIsAboutOpen, setIsCartOpen, setIsMenuOpen]);

	const trackMouse: MouseEventHandler<HTMLDivElement> = (event) => {
		if (!TRACK_CURSOR || !textRef.current || window.innerWidth < 1024) return setLogoCoords(undefined);
		const x = event.pageX - textRef.current.offsetLeft;
		const y = event.pageY - textRef.current.offsetTop;
		const diffY = (y - 80) / 2;
		const diffX = (x - 148) / 2;
		setLogoCoords({ x: x - diffX, y: y - diffY });
	};

	useBgClick("menu", () => {
		setIsMenuOpen(false);
		setLogoCoords(undefined);
	});

	useEffect(() => {
		if (location.key !== lastLocation.current) {
			setIsMenuOpen(false);
			setLogoCoords(undefined);
		}
		lastLocation.current = location.key;
	}, [location.key, setIsMenuOpen]);

	return (
		<>
			<Fader isVisible={!isLoading} className="relative nav-zIndex-logotext">
				<div
					className={classNames(
						"select-none cursor-pointer fixed top-6 left-8 md:left-6 pt:0 lg:top-0 lg:pr-8 lg:pb-8 lg:pt-16 pl-0 lg:left-0 nav-zIndex-logotext",
						styles.logoText
					)}
					data-trigger="menu"
					{...(matches.isMobile && { onClick: toggleMenu })}
					onMouseOver={() => {
						if (matches.isMobile) return;
						setIsMenuOpen(true);
					}}
					onMouseMove={trackMouse}
					onMouseOut={() => {
						if (matches.isMobile) return;
						setIsMenuOpen(false);
					}}
					ref={textRef}
				>
					<img fetchpriority="high" src={logoText} alt="logo text" className="w-32 lg:w-40" loading="eager" />
				</div>
			</Fader>
			<Fader isVisible className="nav-zIndex relative">
				<img
					src={glow}
					alt="glow"
					loading="eager"
					fetchpriority="high"
					ref={rootRef}
					className={classNames(appearOffline ? styles.logoOffline : "", styles.logo, "z-40", {
						[styles.navLogo]: !isLoading,
						[styles.open]: isLogoBig || appearOffline,
					})}
					style={{
						left: isLogoBig ? undefined : left,
						top: isLogoBig ? undefined : top,
						transition: top && left ? "none" : undefined,
					}}
				/>
			</Fader>
			{isMobileChatOpen && (
				<>
					<button className="fixed text-lg right-6 top-6 mt-1 z-50" onClick={() => closeChat()}>
						✕
					</button>
				</>
			)}
		</>
	);
};

export default NavLogo;

import classNames from "classnames";
import { Routes, ShopifyProduct } from "common";
import useShopify from "hooks/useShopify";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageFade from "ui/PageFade";

type shopifyProduct = ShopifyProduct & {
	selectedVariantImage?: {
    altText?: string | null;
    id?: string;
    originalSrc?: string;
  };
}

const Shop: FC = () => {
	const shopify = useShopify();
	const [products, setProducts] = useState<shopifyProduct[]>();

	useEffect(() => {
		shopify.product.fetchAll().then((products: any[]) => {
			setProducts(products as shopifyProduct[]);
		});
	}, [shopify.product]);

	return (
		<PageFade>
			<div className="relative md:py-24 lg:py-48 px-6 md:px-32 grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-12 mt-20 md:mt-0 pb-8 overflow-y-auto">
				{!products && (
					<div className="fixed left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2">LOADING...</div>
				)}
				{products?.map((product, i) => {
					const image: {
						src?: string;
						altText?: string | null | undefined;
						id?: string | undefined;
						originalSrc?: string | undefined;
				} = product.selectedVariantImage || product.images[0];
					return (
						<Link
							to={`${Routes.Product}/${product.handle}`}
							className="relative"
							style={{ paddingTop: "100%" }}
							key={`${product.id}-${i}`}
						>
							<div
								className={classNames(
									"absolute top-0 left-0 bg-contain bg-center w-full h-full transform hover:scale-105 transition-all duration-750"
								)}
							>
								<img src={image.src} alt={product.title} />
							</div>
						</Link>
					);
				})}
			</div>
		</PageFade>
	);
};

export default Shop;

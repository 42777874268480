import usePage from "hooks/usePage";
// import bot from "images/bot.png";
import xray1 from "images/xray-1.png";
import xray2 from "images/xray-2.png";
import { FC } from "react";
import { useHistory } from "react-router";
import PageFade from "ui/PageFade";
import Modal from "ui/UltraModal";
import SecurityRobot from "images/robot.png";
import classNames from "classnames";

const ExRay: FC = () => {
	const history = useHistory();
	const exray = usePage("exray");

	return (
		<PageFade>
			<div className="w-screen h-screen flex relative px-6 md:px-0">
				<img
					fetchpriority="high"
					src={xray1}
					alt="exray badge"
					className="absolute md:top-1/2 left-1/2 transform md:-translate-x-full md:-translate-y-16 lg:-ml-16 lg:mt-0 md:ml-24 md:mt-14 w-52 md:w-auto -translate-x-1/2 top-24"
					loading="eager"
				/>
				<img
					fetchpriority="high"
					src={xray2}
					alt="exray badge"
					className="absolute md:top-1/2 left-1/2 transform md:translate-x-1/2 md:-translate-y-64 lg:-ml-8  md:-ml-36 md:-mt-10 md:w-auto w-44 -translate-x-1/2 bottom-20"
					loading="eager"
				/>
				<Modal
					isCentered
					isOpen
					onClose={() => {
						history.goBack();
					}}
					className="mx-6 md:mx-0"
					title={
						<div className="uppercase">
							<div>SECURITY PROMPT</div>
							COMING SOON
						</div>
					}
				>
					<div className="p-4">
						<div className="pt-8 md:pt-6 pb-8 flex justify-center items-center">
							{/* <img src={bot} className="w-28 md:w-20 h-auto" alt="bot" /> */}
							<img
								alt="security robot"
								src={SecurityRobot}
								className={classNames("w-32 h-32 md:w-28 md:h-28 h-auto object-contain p")}
							/>
						</div>
						<div className="pb-10 md:pb-6 text-center">
							<div dangerouslySetInnerHTML={{ __html: exray?.body }} />
						</div>
					</div>
				</Modal>
			</div>
		</PageFade>
	);
};

export default ExRay;

// https://github.com/web-mech/badwords/issues/93
// We use this Filter with additional function because bad-words doesn't support emoji only text.

// filename bad-words-hacked.js
import Filter from "bad-words";

class FilterHacked extends Filter {
	cleanHacked(string) {
		try {
			return this.clean(string);
		} catch {
			const joinMatch = this.splitRegex.exec(string);
			const joinString = (joinMatch && joinMatch[0]) || "";
			return string
				.split(this.splitRegex)
				.map((word) => {
					return this.isProfane(word) ? this.replaceWord(word) : word;
				})
				.join(joinString);
		}
	}
}

export default FilterHacked;

import { useHistory } from "react-router-dom";
import Modal from "./UltraModal";
import SecurityRobot from "images/robot.png";
import classNames from "classnames";
import { StructuredText } from "react-datocms/structured-text";

export type PagesNotAvailableData = {
	[key: string]: PageNotAvailableProps;
};

export type PageNotAvailableProps = {
	pageIsAvailable: boolean;
	pageIsUnavailableMessage: {
		__typename?: "MenuItemModelPageIsUnavailableMessageField";
		blocks: string[];
		value: any;
	};
};

export const PageNotAvailableModal = (props: PageNotAvailableProps) => {
	const { pageIsUnavailableMessage } = props;

	const history = useHistory();

	return (
		<Modal
			isCentered
			isOpen
			onClose={() => {
				history.goBack();
			}}
			className="mx-6 md:mx-0"
			title={
				<div className="uppercase">
					<div>SECURITY PROMPT</div>
					COMING SOON
				</div>
			}
		>
			<div className="p-4">
				<div className="pt-8 md:pt-6 pb-8 flex justify-center items-center">
					<img
						alt="security robot"
						src={SecurityRobot}
						className={classNames("w-32 h-32 md:w-28 md:h-28 h-auto object-contain p")}
					/>
				</div>
				<div className="pb-10 md:pb-6 text-center whitespace-pre-wrap [&>p]:mb-4 [&>p>a]:underline">
					{pageIsUnavailableMessage?.value?.document?.children[0]?.children[0] ? (
						<StructuredText data={pageIsUnavailableMessage.value} />
					) : (
						<p>Page not available</p>
					)}
				</div>
			</div>
		</Modal>
	);
};

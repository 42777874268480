import { Product, ProductVariant } from "shopify-buy";

export interface GuestbookEntry {
	lat?: number;
	lng?: number;
	msg?: string;
	name?: string;
	photo?: any;
	place?: string;
	time?: string;
	led: string;
}

export interface VideoEntry {
	description: string;
	thumbnail: string;
	time: string;
	title: string;
	videoId: string;
}

export enum Routes {
	About = "/about",
	Cart = "/cart",
	Donate = "/excess",
	ExRay = "/exray",
	Guestbook = "/guestbook",
	Home = "/",
	MobileBadge = "/mobile-badge.html",
	Product = "/product",
	Shop = "/shop",
	Watch = "/watch-now",
	ServiceCenter = "/service-center",
	Projects = "/projects",
	Fund = "/fund",
}

export interface ShopifyProduct extends Omit<Product, "handle"> {
	available?: boolean;
	descriptionHtml: string;
	handle?: string | undefined;
}

export interface ShopifyVariant extends ProductVariant {
	selectedOptions: Array<{ name: string; value: string }>;
}

export enum FormState {
	isLoading,
	isError,
}

export const MEDIA_CONFIG = {
	isMobile: "(max-width: 767px)",
	isTablet: "(min-width: 768px) and (max-width: 1023px)",
	isDesktop: "(min-width: 1024px)",
};

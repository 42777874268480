import classNames from "classnames";

import { FC } from "react";

import styles from "./Fund.module.css";

import { StructuredText } from "react-datocms/structured-text";
import ReactPlayer from "react-player";
import { GlobalSettingModelFundDescriptionField } from "lib/generated/sdk";

const Fund: FC<{ title: string; description: GlobalSettingModelFundDescriptionField; videoUrl: string }> = ({
	title,
	description,
	videoUrl,
}) => {
	return (
		<div className={classNames("h-full w-full flex flex-col", styles.root)}>
			<div className="w-full h-full pt-20">
				<div className="w-full h-full overflow-auto border-t border-black p-6">
					<div className="font-header-2 mb-6 uppercase">{title}</div>
					<div className="mb-6">
						<ReactPlayer controls={true} url={videoUrl} width="auto" height="auto" />
					</div>
					<div className={classNames("font-body mb-28", styles.content)}>
						<StructuredText data={description.value} />
					</div>
				</div>
			</div>
			<div className="fixed left-1/2 bottom-16 transform -translate-x-1/2 z-50">
				<a href="mailto:initiative@ledstudio.com" className="font-header-2 cursor-pointer pill glass-pane">
					APPLY
				</a>
			</div>
		</div>
	);
};

export default Fund;

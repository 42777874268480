import classNames from "classnames";
import SecurityRobot from "images/robot.png";
import Modal from "./UltraModal";
import { useClickAway } from "react-use";
import { useRef, useState } from "react";
import { StructuredText } from "react-datocms/structured-text";

import styles from "../pages/Fund.module.css";

export const TermsModal = ({ isOpen, setIsOpen, terms }) => {
	const modalRef = useRef();

	useClickAway(modalRef, () => {
		setIsOpen(false);
	});

	return isOpen && terms?.value ? (
		<div ref={modalRef}>
			<Modal
				isCentered
				isOpen
				className="mx-6 md:mx-0"
				onClose={() => {
					setIsOpen(false);
				}}
				title={
					<div className="uppercase">
						<div>L.E.D. STUDIO</div>
						TERMS OF SERVICE
					</div>
				}
			>
				<div className="px-4 pt-4 overflow-y-auto pb-4 flex-none">
					<div className={classNames("font-body", styles.content)}>{<StructuredText data={terms?.value} />}</div>
				</div>
			</Modal>
		</div>
	) : null;
};

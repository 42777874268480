import { GraphQLClient } from "graphql-request";

import { getSdkWithHooks } from "../generated/sdk";

const nextGraphQlClient = new GraphQLClient(process.env.REACT_APP_DATO_ENDPOINT || "", {
	headers: {
		Authorization: `Bearer ${process.env.REACT_APP_DATO_API_KEY}`,
	},
});

// Use this server side, it directly calls the Umbraco GraphQL endpoint
export const nextClient = getSdkWithHooks(nextGraphQlClient);

import { MEDIA_CONFIG } from "common";
import { FC } from "react";

import { useMediaQueries } from "@react-hook/media-query";

import DesktopProject from "./Project.desktop";
import MobileProject from "./Project.mobile";
import { GetProjectsQuery } from "lib/generated/sdk";

interface ProjectProps {
	data: GetProjectsQuery;
}

const Project: FC<ProjectProps> = ({ data }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);

	const Widget = matches.isMobile ? MobileProject : DesktopProject;

	return <Widget data={data} />;
};

export default Project;

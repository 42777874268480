import { MEDIA_CONFIG } from "common";
import { FC } from "react";

import { useMediaQueries } from "@react-hook/media-query";

import { GetSettingsQuery } from "lib/generated/sdk";
import MobileFund from "./Fund.mobile";
import DesktopFund from "./Fund.desktop";

interface ProjectProps {
	data: GetSettingsQuery;
}

const Fund: FC<ProjectProps> = ({ data }) => {
	const { fundTitle, fundDescription, fundVideoLink } = data?.globalSetting;

	const { matches } = useMediaQueries(MEDIA_CONFIG);

	const Widget = matches.isMobile ? MobileFund : DesktopFund;

	return <Widget title={fundTitle} description={fundDescription} videoUrl={fundVideoLink} />;
};

export default Fund;

import { MEDIA_CONFIG } from "common";
import { FC } from "react";
import PageFade from "ui/PageFade";

import { useMediaQueries } from "@react-hook/media-query";

import DesktopWatch from "./Watch.desktop";
import MobileWatch from "./Watch.mobile";

const Watch: FC = () => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);

	const Widget = matches.isMobile ? MobileWatch : DesktopWatch;

	return (
		<PageFade>
			<Widget />
		</PageFade>
	);
};

export default Watch;

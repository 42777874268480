import classNames from "classnames";
import { GuestbookEntry } from "common";
import useBgClick from "hooks/useBgClick";
import idCard from "images/card-blank.png";
import moment from "moment";
import { CSSProperties, forwardRef } from "react";

import styles from "./IDCard.module.css";

export interface IDCardProps {
	className?: string;
	onClose?: () => void;
	style?: CSSProperties;
	value?: GuestbookEntry;
}

const IDCard = forwardRef<HTMLDivElement, IDCardProps>(({ className, value, onClose, style }, ref) => {
	useBgClick("id-card", (e) => {
		e.preventDefault()
		onClose && onClose();
	});

	return (
		<div
			className={classNames("inline-block overflow-hidden relative text-black text-left", className)}
			data-trigger="id-card"
			ref={ref}
			style={style}
		>
			<div
				className={classNames("bg-cover bg-center absolute", styles.img)}
				style={{ backgroundImage: `url(${value?.photo})` }}
			/>
			<img
				src={idCard}
				alt="id"
				className={classNames(styles.card, "relative")}
				crossOrigin="anonymous"
				loading="lazy"
			/>
			<div className={classNames("absolute", styles.copy)}>
				<div className={classNames(styles.header, "mb-1 truncate whitespace-nowrap uppercase")}>{value?.name}</div>
				<div className="mb-3 break-words">{value?.place}</div>
				<div className="break-words">{value?.msg}</div>
				<div className="absolute bottom-0 right-0">{moment(value?.time).format("MM.DD.YYYY")}</div>
				<div className={classNames("absolute", styles.led)}>{value?.led}</div>
			</div>
		</div>
	);
});

export default IDCard;

import { VideoEntry } from "common";
import useSWR from "swr";

import fetcher from "./fetcher";

const useVideos = () => {
	const { data, error, mutate } = useSWR<any[]>(`/api/listVideos`, fetcher, {
		refreshInterval: 20000,
		shouldRetryOnError: false,
	});

	const videos = data
		?.filter((video) => video.snippet.resourceId.kind === "youtube#video")
		.map<VideoEntry>((video) => {
			return {
				description: video.snippet.description,
				thumbnail: video.snippet.thumbnails.high.url,
				time: video.snippet.publishedAt,
				title: video.snippet.title,
				videoId: video.snippet.resourceId.videoId,
			};
		});

	return {
		videos,
		isError: error,
		isLoading: !error && !data,
		refresh: mutate,
	};
};

export default useVideos;

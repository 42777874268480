import Client from "shopify-buy";

//@ts-ignore
export const client = Client.buildClient({
	domain: process.env.REACT_APP_SHOPIFY_URL || "",
	storefrontAccessToken: process.env.REACT_APP_SHOPIFY_TOKEN || "",
});

const useShopify = () => {
	return client;
};

export default useShopify;

import classNames from "classnames";
import SecurityRobot from "images/robot.png";
import Modal from "./UltraModal";
import { useClickAway } from "react-use";
import { useRef, useState } from "react";

export const NewsLetterModal = ({ isOpen, setIsOpen }) => {
	const [newsLetterState, setNewsLetterState] = useState<"active" | "sent">("active");
	const modalRef = useRef();

	useClickAway(modalRef, () => {
		setIsOpen(false);
	});

	return isOpen ? (
		<div ref={modalRef}>
			<Modal
				isCentered
				isOpen
				className="mx-6 md:mx-0"
				onClose={() => {
					setIsOpen(false);
				}}
				title={
					<div className="uppercase">
						<div>SUBSCRIBE TO THE</div>
						L.E.D. STUDIO NEWSLETTER
					</div>
				}
			>
				<div className="p-4">
					<div className="pt-8 md:pt-6 pb-8 flex justify-center">
						<img
							alt="security robot"
							src={SecurityRobot}
							className={classNames("w-32 h-32 md:w-28 md:h-28 h-auto object-contain p")}
						/>
					</div>

					<div className="flex justify-center ">
						{newsLetterState === "active" ? (
							<form
								onSubmit={(e) => {
									e.preventDefault();
									const form = new FormData(e.target as HTMLFormElement);
									fetch("/api/signNewsletter", {
										method: "POST",
										body: JSON.stringify({
											email: form.get("email"),
										}),
									})
										.then((res) => res.json())
										.then((res) => {
											if (res.succes) {
												setNewsLetterState("sent");
											}
										});
								}}
								className="flex pointer-events-auto justify-between items-center  border border-gray-1"
							>
								<input
									required
									type="email"
									name="email"
									className="placeholder-black px-4 py-2 w-full placeholder-gray-2"
									placeholder="Your email..."
								/>

								<button
									className="bg-gray-1 px-4 py-2 font-header-2 h-full focus:bg-black focus:text-white"
									type="submit"
								>
									SUBMIT
								</button>
							</form>
						) : (
							<button
								className="inline-block pointer-events-auto"
								onClick={() => {
									setNewsLetterState("active");
								}}
							>
								<p className="font-header-2">THANKS!</p>
							</button>
						)}
					</div>
				</div>
			</Modal>
		</div>
	) : null;
};

import classNames from "classnames";
import { MEDIA_CONFIG, Routes, ShopifyProduct, ShopifyVariant } from "common";
import useCartMenu from "hooks/useCartMenu";
import useShopify from "hooks/useShopify";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Price, formatPrice } from "ui/Cart";
import PageFade from "ui/PageFade";
import { Slider } from "ui/Slider";

import { useMediaQueries } from "@react-hook/media-query";

import { CartContext } from "./App";
import styles from "./Product.module.css";

export interface ProductProps {
	visible?: boolean;
}

const Product: FC<ProductProps> = ({ visible }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const shopify = useShopify();
	const params = useParams<{ handle: string }>();
	const [quantity, setQuantity] = useState(1);
	const [variants, setVariants] = useState<Record<string, string>>({});
	const [product, setProduct] = useState<ShopifyProduct>();
	const [isGalleryOpen, setIsGalleryOpen] = useState<number>();
	const [, setIsCartOpen] = useCartMenu(false);

	const getProductWidth = () => {
		if (matches.isDesktop) return "calc(50% - 124px)";
		if (matches.isTablet) return "calc(50% - 36px)";
	};

	const selectVariant = useCallback(
		(option: string, value: string) => {
			setVariants({ ...variants, [option]: value });
		},
		[variants]
	);

	const selectedVariant = product?.variants.find((variant) => {
		for (const key in variants) {
			const selectedOption = (variant as ShopifyVariant).selectedOptions.find((option) => option.name === key);
			if (!selectedOption || selectedOption.value !== variants[key]) return false;
		}
		return true;
	});

	useEffect(() => {
		const onKeyPress = (e: KeyboardEvent) => {
			if (e.key === "Escape" && isGalleryOpen !== undefined) {
				setIsGalleryOpen(undefined);
			}
		};
		document.addEventListener("keydown", onKeyPress);
		return () => document.removeEventListener("keydown", onKeyPress);
	}, [isGalleryOpen]);

	useEffect(() => {
		shopify.product.fetchByHandle(params.handle).then((product) => {
			setProduct(product as any);
		});
	}, [params.handle, shopify.product]);

	useEffect(() => {
		if (Object.keys(variants).length !== 0) return;
		const newVariants: Record<string, string> = {};
		const newVariant = selectedVariant || product?.variants[0];
		if (!newVariant) return;
		(newVariant as ShopifyVariant).selectedOptions.forEach((option) => {
			newVariants[option.name] = option.value;
		});
		setVariants(newVariants);
	}, [selectedVariant, product?.variants, selectVariant, variants]);

	const price = selectedVariant?.price || product?.variants[0].price;

	// @ts-ignore
	const outOfStock = !Object.keys(variants).length || !selectedVariant?.available;

	const quantityCell = (
		<div className="md:flex-none flex-auto md:border-l border-black lg:py-4 md:py-2 md:px-4 py-4 px-6 md:block flex items-center justify-between">
			<div className="font-header-2 w-24 lg:w-44">QUANTITY</div>
			<div className="flex items-center md:mt-4 lg:mt-6 -ml-1">
				<button className="w-5 text-center text-center" onClick={() => quantity > 1 && setQuantity(quantity - 1)}>
					-
				</button>
				<span className="mx-2 w-8 text-center">{quantity}</span>
				<button className="w-5 text-center" onClick={() => setQuantity(quantity + 1)}>
					+
				</button>
			</div>
		</div>
	);

	return (
		<CartContext.Consumer>
			{([, cartApi]) => {
				const addToCart = () => {
					if (!selectedVariant) return;
					cartApi.addItem(`${selectedVariant.id}`, quantity);
					setIsCartOpen(true);
				};

				return (
					<PageFade>
						<div
							className={classNames(
								styles.root,
								"w-full h-full flex-auto relative overflow-y-auto mt-20 md:mt-0 overflow-hidden"
							)}
						>
							{product && visible && (
								<div
									className={classNames({ "absolute top-1/2 transform -translate-y-1/2": !matches.isMobile })}
									style={matches.isDesktop ? { width: "150%", left: "-25%" } : undefined}
								>
									<Slider
										images={product?.images}
										itemsToCalculateWidthOn={matches.isDesktop ? 4 : matches.isTablet ? 2 : 1}
										itemsToShow={
											matches.isDesktop
												? Math.min(product?.images.length, 4)
												: matches.isTablet
												? Math.min(product?.images.length, 2)
												: 1
										}
										offset={matches.isDesktop ? 1 : undefined}
										onSlideClick={!matches.isMobile ? (index) => setIsGalleryOpen(index) : undefined}
									/>
								</div>
							)}
							<div
								className={classNames(
									"border border-b-0 md:border-b border-r-0 border-l-0 md:border-r md:border-l border-black flex flex-col md:fixed right-6 lg:right-20 top-1/2 transform md:-translate-y-1/2",
									{ "glass-pane": !matches.isMobile }
								)}
								style={{
									maxWidth: matches.isMobile ? undefined : 530,
									width: getProductWidth(),
									maxHeight: matches.isMobile ? undefined : 530,
								}}
							>
								<div className="hidden md:block font-header-2 lg:py-4 md:py-2 md:px-4 py-4 px-6 border-b border-black desktop-hover:hover:underline md:flex-none select-none">
									<Link to={Routes.Shop}>&lt; BACK TO SHOP</Link>
								</div>
								{!product ? (
									<div className="h-screen lg:py-4 md:py-2 md:px-4 py-4 px-6">LOADING...</div>
								) : (
									<>
										<div
											className={classNames(
												"lg:py-4 md:py-2 md:px-4 py-4 px-6 border-b border-black md:flex-none  select-none",
												{
													"font-header-1": !matches.isMobile,
													"font-header-2": matches.isMobile,
												}
											)}
										>
											{product?.title}
										</div>
										<div className="font-header-2 flex items-stretch border-b border-black md:flex-none  select-none">
											<div className="lg:py-4 md:py-2 md:px-4 py-4 px-6 w-4/12 border-r border-black text-center">
												{/* @ts-ignore */}
												<div className="h-full flex justify-center items-center truncate w-full min-w-0">
													{formatPrice(price as unknown as Price)}
												</div>
											</div>
											{matches.isMobile ? (
												quantityCell
											) : (
												<>
													{outOfStock ? (
														<button className="flex-grow flex-shrink btn pointer-events-none uppercase">
															Inquire Below
														</button>
													) : (
														<button onClick={addToCart} className="flex-grow flex-shrink btn">
															ADD TO CART
														</button>
													)}
												</>
											)}
										</div>
										{product?.options.map((option, index) => (
											<div
												className="border-b border-black border-b border-black uppercase flex flex-row md:flex-none  select-none"
												key={option.name}
											>
												<div className="flex-grow flex-shrink flex flex-col lg:py-4 md:py-2 md:px-4 py-4 px-6">
													<div className="font-header-2">{option.name}</div>
													<div className="flex mt-4 lg:mt-6 -ml-2">
														{/* @ts-ignore */}
														{option.values.map((value) => (
															<div
																className={classNames("px-2 cursor-pointer desktop-hover:hover:underline", {
																	"font-medium underline": variants[option.name] === value.value,
																})}
																key={value.value}
																onClick={() => selectVariant(option.name, value.value)}
															>
																{value.value}
															</div>
														))}
													</div>
												</div>
												{index === 0 && !matches.isMobile && quantityCell}
											</div>
										))}
										<div
											className="lg:py-4 md:py-2 md:px-4 pt-4 pb-16 px-6 overflow-auto md:flex-auto select-none"
											dangerouslySetInnerHTML={{ __html: product?.descriptionHtml || "" }}
										/>
									</>
								)}
							</div>
							{!outOfStock && (
								<button
									className="pill glass-pane fixed bottom-16 left-1/2 transform -translate-x-1/2 inline-block md:hidden cursor-pointer z-10"
									onClick={addToCart}
								>
									ADD TO CART
								</button>
							)}
							{isGalleryOpen !== undefined && !matches.isMobile && (
								<div className="h-full w-full fixed top-0 left-0 z-100 relative bg-white overflow-hidden flex">
									<div className="relative h-full w-full">
										{product && (
											<div className="absolute top-1/2 transform -translate-y-1/2 w-full left-0 overflow-hidden">
												<Slider
													images={product.images}
													itemsToShow={1}
													initialSlide={isGalleryOpen}
													maxHeight="90vh"
													prevClassName="translate-x-10"
													nextClassName="-translate-x-10"
												/>
											</div>
										)}
									</div>
									<button
										className="text-3xl absolute top-12 right-12 z-100"
										onClick={() => setIsGalleryOpen(undefined)}
									>
										✕
									</button>
								</div>
							)}
						</div>
					</PageFade>
				);
			}}
		</CartContext.Consumer>
	);
};

export default Product;

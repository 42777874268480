import { useCallback, useEffect, useState } from "react";
import { Cart } from "shopify-buy";

import { client } from "./useShopify";

type cart = Cart & {
	lineItems: any[];
	subtotalPrice: string | number;
}

export type CartState = {
	cart: cart | null;
	isLoading: boolean;
};

export type Checkout = {
	addItem: (variantId: string, quantity: number) => Promise<void>;
	removeItem: (lineItemId: string) => Promise<void>;
	updateQuantity: (lineItemId: string, quantity: number) => Promise<void>;
};

const useCart = (): [CartState, Checkout] => {
	const [cart, setCart] = useState<any | null>(null);
	const [checkoutId, setCheckoutId] = useState<string>("");
	// const [isLoading, setIsLoading] = useState<boolean>(true);

	const initializeCart = async () => {
		const id = localStorage.getItem("hp.checkoutId");

		let newCart: any;
let newCheckoutId: string;
		if (id) {
			newCart = await client.checkout.fetch(id)
			if (!newCart) {
				localStorage.removeItem("hp.checkoutId");
			}
			newCheckoutId = newCart.id as string;
		} else {
			newCart = await client.checkout.create();
			newCheckoutId = newCart.id as string;
			localStorage.setItem("hp.checkoutId", newCheckoutId);
		}

		setCheckoutId(newCheckoutId);
		setCart(newCart);
		// setIsLoading(false);
	};

	useEffect(() => {
		initializeCart();
	}, []);

	const addItem = useCallback(
		async (variantId: string, quantity: number) => {
			// setIsLoading(true);
			const lineItemsToAdd = [{ variantId: variantId, quantity: quantity }];
			const newCart = await client.checkout.addLineItems(checkoutId, lineItemsToAdd);
			setCart(newCart);
			// setIsLoading(false);
		},
		[checkoutId]
	);

	const updateQuantity = useCallback(
		async (lineItemId: string, quantity: number) => {
			// setIsLoading(true);
			const lineItemsToUpdate = [{ id: lineItemId, quantity }];
			const newCart = await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
			setCart(newCart);
			// setIsLoading(false);
		},
		[checkoutId]
	);

	const removeItem = useCallback(
		async (lineItemId: string) => {
			// setIsLoading(true);
			const lineItemIdsToRemove = [lineItemId];
			const newCart: any = await client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove);
			setCart(newCart);
			// setIsLoading(false);
		},
		[checkoutId]
	);

	const state = { cart, isLoading: false };
	const api = {
		addItem,
		updateQuantity,
		removeItem,
	};

	return [state, api];
};

export default useCart;

import classNames from "classnames";
import { MEDIA_CONFIG } from "common";
import { CSSProperties, FC, PointerEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Image } from "shopify-buy";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useMediaQueries } from "@react-hook/media-query";

export interface SliderProps {
	images: Image[];
	initialSlide?: number;
	itemsToShow?: number;
	// Calculates slider side on items width
	// However minimum required items is more then items required by CMS
	itemsToCalculateWidthOn?: number;
	offset?: number;
	onSlideClick?: (index: number) => void;
	maxHeight?: CSSProperties["height"];
	prevClassName?: string;
	nextClassName?: string;
}

interface SlideConfig {
	index: number;
	behavior: ScrollBehavior;
}

export const Slider: FC<SliderProps> = ({
	images,
	initialSlide,
	itemsToShow = 4,
	itemsToCalculateWidthOn,
	offset = 0,
	onSlideClick,
	maxHeight,
	prevClassName,
	nextClassName,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [slide, setSlide] = useState<SlideConfig>({ index: 0, behavior: "smooth" });
	const [start, setStart] = useState<{ container?: number; mouse?: number }>();
	const [slotWidth, setSlotWidth] = useState(0);
	const [paddingElements, setPaddingElements] = useState([]);
	const [loaded, setLoaded] = useState(0);
	const { matches } = useMediaQueries(MEDIA_CONFIG);

	useEffect(() => {
		if (loaded !== images.length) return;
		const onResize = () => {
			const slotWidth = containerRef?.current?.querySelector(".slot")?.clientWidth || 0;
			setSlotWidth(slotWidth);
			// setSlide(0);
		};
		window.addEventListener("resize", onResize);
		onResize();
		return () => window.removeEventListener("resize", onResize);
	}, [loaded, images.length]);

	const slots = useMemo(() => {
		const slotsToShow = [];
		let padding = 0;
		let max = 0;

		if (images.length < itemsToShow) {
			max = itemsToShow;
			padding = images.length - 1;
		} else {
			max = images.length;
			padding = itemsToShow - 1;
		}

		setPaddingElements(Array.from({ length: padding }));
		for (let i = 0; i < max; i++) {
			slotsToShow.push({ image: images[i] });
		}

		return slotsToShow;
	}, [images, itemsToShow]);

	const viewSlide = useCallback(
		(viewSlide: SlideConfig) => {
			if (viewSlide.index < 0 || viewSlide.index >= slots.length) return;

			const offset = slotWidth * viewSlide.index;

			containerRef.current.style.transform = `translateX(${-offset}px)`;
		},
		[slotWidth, slots.length]
	);

	const onPointerDown = useCallback<PointerEventHandler<HTMLDivElement>>((e) => {
		const clientX = e.clientX;
		setStart({ mouse: clientX, container: containerRef.current?.scrollLeft });
	}, []);

	const onPointerMove = useCallback<PointerEventHandler<HTMLDivElement>>(
		(e) => {
			if (start === undefined || images.length === 1) return;
			const clientX = e.clientX;
			const delta = clientX - Number(start.mouse);
			// containerRef.current.style.transform = `translateX(${Number(start.container) - delta}px)`;
		},
		[images.length, start]
	);

	const onPointerUp = useCallback<PointerEventHandler<HTMLDivElement>>(
		(e) => {
			if (start === undefined) return;
			const clientX: number = e.clientX;
			const delta = clientX - Number(start.mouse);
			setStart(undefined);
			const adjustSlide = Math.abs(delta) > slotWidth * 0.15;
			if (images.length === 1 || Math.abs(delta) <= 5) {
				onSlideClick?.(slide.index);
				return;
			}
			if (adjustSlide) {
				const nextSlide = delta < 0 ? slide.index + 1 : slide.index - 1;
				if (nextSlide < 0 || nextSlide >= images.length) {
					viewSlide(slide);
					return;
				}

				setSlide({ index: nextSlide, behavior: "smooth" });
			} else {
				viewSlide(slide);
			}
		},
		[images.length, onSlideClick, slide, slotWidth, start, viewSlide]
	);

	// const onMouseUp = useCallback(
	// 	(e: MouseEvent | TouchEvent) => {
	// 		if (start === undefined) return;
	// 		const clientX: number = (e as TouchEvent).changedTouches
	// 			? (e as TouchEvent).changedTouches[0]?.pageX
	// 			: (e as MouseEvent).clientX;
	// 		const delta = clientX - Number(start.mouse);
	// 		setStart(undefined);
	// 		const adjustSlide = Math.abs(delta) > slotWidth * 0.15;
	// 		if (images.length === 1 || Math.abs(delta) <= 5) {
	// 			onSlideClick?.(slide.index);
	// 			return;
	// 		}
	// 		if (adjustSlide) {
	// 			const nextSlide = delta < 0 ? slide.index + 1 : slide.index - 1;
	// 			if (nextSlide < 0 || nextSlide >= images.length) {
	// 				viewSlide(slide);
	// 				return;
	// 			}
	// 			setSlide({ index: nextSlide, behavior: "smooth" });
	// 		} else {
	// 			viewSlide(slide);
	// 		}
	// 	},
	// 	[slide, slotWidth, start, viewSlide, images.length, onSlideClick]
	// );

	// Set controlled slide
	useEffect(() => {
		if (initialSlide === undefined) return;
		setSlide({ index: initialSlide, behavior: "auto" });
	}, [initialSlide]);

	const prev = useCallback(() => {
		setSlide((_slide) => {
			return { index: Math.max(_slide.index - 1, 0), behavior: "smooth" };
		});
	}, []);
	const next = useCallback(() => {
		setSlide((_slide) => {
			return { index: Math.min(_slide.index + 1, images.length), behavior: "smooth" };
		});
	}, []);

	// Set controlled slide
	useEffect(() => {
		viewSlide(slide);
	}, [viewSlide, slide]);

	return (
		<div className="relative">
			<div className="flex items-center transition-transform duration-200" ref={containerRef}>
				{offset ? <div className="flex-none" style={{ width: offset * slotWidth }} /> : null}
				{slots.map((slot, i) => {
					return (
						<div
							style={{ width: `${(1 / itemsToCalculateWidthOn) * 100}%` }}
							className="flex-none h-full flex items-center justify-center select-none slot relative"
							key={i}
						>
							<img
								fetchpriority="high"
								src={slot.image?.src}
								alt="created at"
								onLoad={() => setLoaded(loaded + 1)}
								style={{ maxHeight }}
								className="w-auto"
								loading="eager"
							/>
							<div className="opacity-0 absolute h-4 w-4">{i}</div>
						</div>
					);
				})}
				{paddingElements.map((_, i) => (
					<div
						style={{ width: `${(1 / itemsToCalculateWidthOn) * 100}%` }}
						className="flex-none h-full flex items-center justify-center select-none slot relative"
						key={i}
					/>
				))}
			</div>
			<div
				className={classNames({ "cursor-pointer": !!onSlideClick }, "absolute top-0 left-0 w-full h-full z-10")}
				onPointerDown={onPointerDown}
				onPointerUp={onPointerUp}
				onPointerMove={onPointerMove}
			/>
			<div className="absolute bottom-0 h-10 bg-white z-10 w-full" />
			{!matches.isMobile && images.length > 1 && (
				<>
					<button
						onClick={prev}
						className={classNames("absolute z-20 top-1/2 transform -translate-y-1/2", prevClassName)}
						style={{ left: offset * slotWidth }}
					>
						<ChevronLeftIcon className="w-8 h-8" />
					</button>
					<button
						onClick={next}
						className={classNames("absolute z-20 top-1/2 transform -translate-y-1/2", nextClassName)}
						style={{ left: offset * slotWidth + slotWidth - 32 }}
					>
						<ChevronRightIcon className="w-8 h-8" />
					</button>
				</>
			)}
			{matches.isMobile && (
				<div className="absolute w-full bottom-8 flex gap-4 justify-center items-center z-20">
					{images.map((_, i) => {
						return (
							<div
								className={classNames("w-1 h-1 rounded-full", {
									"bg-gray-400": slide.index !== i,
									"bg-gray-600": slide.index === i,
								})}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};

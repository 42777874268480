import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";

export interface FaderProps {
	appear?: boolean;
	className?: string;
	delay?: number;
	duration?: number;
	isVisible?: boolean;
	removeChildren?: boolean;
	children: ReactNode;
	as?: string;
}

export function Fader({
	appear = true, 
	children,
	className,
	isVisible,
	removeChildren = true,
	as,
}: FaderProps) {
	const rootRef = useRef<HTMLDivElement>(null);
	const [isHidden, setIsHidden] = useState(!isVisible);
	const [isRendered, setIsRendered] = useState(false);

	const onTransitionEnd = () => {
		setIsHidden(!!rootRef.current?.classList.contains("opacity-0"));
	};

	useEffect(() => {
		setIsRendered(true);
	}, []);

	useEffect(() => {
		if (isVisible) {
			setIsHidden(false);
		}
	}, [isVisible]); 

	const isReallyVisible = appear && !isRendered ? false : isVisible;
	const Element = (as || "div") as any;

	return ( 
		<Element
			className={classNames(className, {
				"duration-1000": className ? !className.includes('duration-'): true,
				"opacity-1": isReallyVisible,
				"opacity-0": !isReallyVisible,
				"max-w-0 max-h-0 w-0 h-0 overflow-hidden": isHidden,
			})}
			onTransitionEnd={onTransitionEnd}
			ref={rootRef}
		>
			{(!removeChildren || !isHidden) && children}
		</Element>
	);
}

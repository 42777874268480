import { GuestbookEntry } from "common";
import useSWR from "swr";

import { File } from "@google-cloud/storage";

import fetcher from "./fetcher";

function mapEntries(data?: any[]) {
	return data?.map<GuestbookEntry>((file) => {
		return {
			...file.metadata,
			photo: file.mediaLink,
		};
	});
}

const useGuestbook = () => {
	const { data, error, mutate } = useSWR<File[]>(`/api/listGuestbook`, fetcher);

	return {
		// Filter out youtube data
		entries: mapEntries(data?.filter(data => !data.id.includes('youtube_data'))),
		isError: error,
		isLoading: !error && !data,
		refresh: async () => {
			const items = await mutate();
			return mapEntries(items);
		},
	};
};

export default useGuestbook;

import classNames from "classnames";
import { FormState, GuestbookEntry, MEDIA_CONFIG } from "common";
import glow from "images/glow.png";
import moment from "moment";
import { CSSProperties, FC, FormEventHandler, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { usePlacesWidget } from "react-google-autocomplete";
import Webcam from "react-webcam";
import { resizeImage } from "utils/resizeImage";

import { useMediaQueries } from "@react-hook/media-query";
import Filter from "utils/FilterHacked";
import { words } from "utils/words";

export interface IDInputProps {
	onSuccess?: (entry: GuestbookEntry) => void;
	value?: GuestbookEntry;
	width?: CSSProperties["width"];
	profoundWords?: string;
}

const time = moment().toISOString();
const led = Math.random().toString().slice(2, 9);
const ID_ROOT = 99999999999999999;

function urltoFile(url: string, filename: string, mimeType: string) {
	return fetch(url)
		.then(function (res) {
			return res.arrayBuffer();
		})
		.then(function (buf) {
			return new File([buf], filename, { type: mimeType });
		});
}

const MAX_CHARS = 140;

const IDInput: FC<IDInputProps> = ({ onSuccess, value: defaultValue, profoundWords }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const [place, setPlace] = useState<google.maps.places.PlaceResult>();
	const formRef = useRef<HTMLFormElement>(null);
	const webcamRef = useRef<Webcam>(null);
	const [value, setValue] = useState<GuestbookEntry>(defaultValue || { time, led });
	const [file, setFile] = useState<File>();
	const [formState, setFormState] = useState<FormState>();
	const [isNSFW, setIsNSFW] = useState<boolean>(false);
	const [isMsgFocused, setIsMsgFocused] = useState(false);
	const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
	const isDisabled = !value?.name || !place || !value.time || !file;
	const preview = useMemo(() => file && URL.createObjectURL(file), [file]);

	const filter = useMemo(() => {
		const filter = new Filter();
		filter.addWords(...words);

		if (profoundWords) {
			const split = profoundWords.split(", ");
			filter.addWords(...split);
		}
		return filter;
	}, [profoundWords]);

	const { getInputProps, open: openDropzone } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: (acceptedFiles) => {
			setFile(acceptedFiles[0]);
		},
	});

	const { ref } = usePlacesWidget<HTMLInputElement>({
		apiKey: process.env.GOOGLE_YOUTUBE_KEY || "",
		options: { types: ["geocode"] },
		onPlaceSelected: (result) => {
			setPlace(result);
		},
	});

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
		event.preventDefault();
		if (!value || !file) return;
		const resizedFile = await resizeImage(file, 250);
		signGuestbook({
			...value,
			place: place?.formatted_address,
			lat: place?.geometry?.location?.lat(),
			lng: place?.geometry?.location?.lng(),
			photo: resizedFile,
		});
	};

	const signGuestbook = async (entry: GuestbookEntry) => {
		setFormState(FormState.isLoading);
		setIsNSFW(false);

		entry.name = filter.cleanHacked(entry.name);
		entry.msg = filter.cleanHacked(entry.msg);

		try {
			const nsfwFormData = new FormData();
			nsfwFormData.append("file", entry.photo);

			const nsfwPrediction = await (
				await fetch("https://nsfw-check-5iay5ysipq-uw.a.run.app", {
					method: "POST",
					body: nsfwFormData,
				})
			).json();
			if (nsfwPrediction[0].className !== "Neutral" && nsfwPrediction[0].className !== "Drawing") {
				setFormState(FormState.isError);
				setIsNSFW(true);
				return;
			}
			const filename = `${ID_ROOT - Date.now()}-${entry.led}`;
			const res = await fetch(`/api/signGuestbook?data=${JSON.stringify({ ...entry, photo: filename })}`);
			const { url, fields } = await res.json();
			const formData = new FormData();
			Object.entries({ ...fields, file: entry.photo }).forEach(([key, value]) => {
				formData.append(key, value as string);
			});
			const upload = await fetch(url, {
				method: "POST",
				body: formData,
				mode: "cors",
			});
			if (upload.ok) {
				onSuccess && onSuccess({ ...entry, photo: URL.createObjectURL(entry.photo) });
			} else {
				setFormState(FormState.isError);
			}
		} catch (error) {
			console.log("error", error);
			setFormState(FormState.isError);
		}
	};

	return (
		<form onSubmit={handleSubmit} ref={formRef}>
			<div className="md:bg-white rounded-xl md:p-8 md:pl-6 flex flex-col md:flex-row items-stretch w-full md:mb-0 mb-28">
				<div className="flex-none md:w-36 text-center flex items-stretch md:flex-col w-full">
					<div
						className="bg-gray-1 rounded-lg w-28 h-28 flex flex-col items-center justify-center text-orange-1 cursor-pointer bg-center bg-cover md:m-auto font-header-2 flex-none"
						style={{ backgroundImage: `url(${preview})` }}
						onClick={() => (!matches.isMobile ? setIsPhotoModalOpen(true) : openDropzone())}
					>
						<input {...getInputProps()} name="photo" />
						{!file && "ADD PHOTO"}
					</div>
					<div className="flex-auto md:flex-none pt-8 md:pt-0">
						<div className="font-header-2 inline-block mt-3 relative">
							<img
								src={glow}
								className="absolute w-36 h-auto left-1/2 transform -translate-x-1/2 -bottom-12"
								alt="glow"
								loading="lazy"
							/>
							<div className="flex justify-between relative">
								<div>L.E.D.</div>
								<div>STUDIO</div>
							</div>
							<div className="relative">HP oper.SYSTEM V1.1</div>
						</div>
						<div className="relative mt-4">No. LED 0000000</div>
					</div>
				</div>
				<div className="flex-auto mt-6 md:mt-0 md:ml-5 flex flex-col items-stretch relative">
					<input
						className="flex-none input h-6"
						onChange={(event) => setValue({ ...value, name: event.target.value })}
						placeholder="Your Name"
						type="text"
						value={value?.name || ""}
					/>
					<input className="mt-2 flex-none input h-6" placeholder="Your Location" ref={ref} type="text" />
					<textarea
						className="mt-2 flex-auto input h-20 md:h-auto"
						maxLength={MAX_CHARS}
						onBlur={() => setIsMsgFocused(false)}
						onChange={(event) => setValue({ ...value, msg: event.target.value })}
						onFocus={() => setIsMsgFocused(true)}
						placeholder="Your Contact (Instagram, Email or Website)"
						value={value?.msg || ""}
					/>
					<div className="flex justify-between mt-5 relative">
						<div>DATE OF ISSUE</div>
						<div>XX.XX.XXXX</div>
					</div>
					{isMsgFocused && (
						<div className="font-caption absolute bg-gray-2 text-white bottom-11 right-2 p-1">
							{value?.msg?.length || 0} / {MAX_CHARS}
						</div>
					)}
				</div>
				{isPhotoModalOpen && (
					<div
						className="glass-pane rounded-lg border border-black p-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
						style={{ width: 400 }}
					>
						<div
							className="bg-gray-1 rounded-lg w-28 h-28 flex flex-col items-center justify-center text-orange-1 cursor-pointer bg-center bg-cover m-auto font-header-2 flex-none text-center relative overflow-hidden"
							style={{ backgroundImage: `url(${preview})` }}
							onClick={() => {
								if (file) {
									setFile(undefined);
								}
							}}
						>
							{!file && (
								<Webcam
									audio={false}
									className="h-full w-full absolute left-0 top-0 object-cover"
									imageSmoothing
									minScreenshotHeight={1000}
									minScreenshotWidth={1000}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
								/>
							)}
							<input {...getInputProps()} />
							{file && <div>CHANGE PHOTO</div>}
						</div>
						<div className="flex justify-center mt-4">
							{file ? (
								<button className="pill" onClick={() => setIsPhotoModalOpen(false)}>
									CONTINUE
								</button>
							) : (
								<>
									<button
										className="pill mr-3"
										onClick={(event) => {
											event.preventDefault();
											openDropzone();
										}}
									>
										UPLOAD PHOTO
									</button>
									<button
										className="pill ml-3"
										onClick={async (event) => {
											event.preventDefault();
											const preview = webcamRef.current?.getScreenshot();
											if (!preview) return;
											const file = await urltoFile(preview, `${Date.now()}.jpg`, "image/jpeg");
											setFile(file);
										}}
									>
										TAKE PHOTO
									</button>
								</>
							)}
						</div>
					</div>
				)}
			</div>
			<div className="text-center md:text-left w-full absolute md:static bottom-16 left-0">
				{isNSFW && <p className="text-red-500">This image was deemed inappropriate</p>}
				<button
					className={classNames({
						"btn mt-4 w-full h-14": !matches.isMobile,
						"pill glass-pane mx-auto": matches.isMobile,
					})}
					disabled={isDisabled || formState === FormState.isLoading}
				>
					SIGN GUESTBOOK
				</button>
			</div>
		</form>
	);
};

export default IDInput;

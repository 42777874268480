import { useMediaQueries } from "@react-hook/media-query";
import { MEDIA_CONFIG, Routes } from "common";
import useCartMenu from "hooks/useCartMenu";
import useLiveStream from "hooks/useLiveStream";
import useVideos from "hooks/useVideos";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Fader } from "ui/Fader";
import Video from "ui/Video";

const Watch: FC = () => {
	const history = useHistory();
	const liveVideoId = useLiveStream();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { videoId } = useParams<{ videoId: string }>();
	const { videos } = useVideos();
	const currentVideo = videos?.find((video) => video.videoId === videoId);

	const [isCartOpen] = useCartMenu();
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	useEffect(() => {
		if (matches.isMobile && !!isCartOpen) {
			document.body.classList.add("overflow-hidden");
		}
		return () => {
			document.body.classList.remove("overflow-hidden");
		};
	}, [isCartOpen, matches.isMobile]);

	const archiveContent = (
		<div className="h-full mt-20">
			<div className="h-full overflow-auto px-6 pb-6">
				<div className="grid grid-cols-2 gap-4">
					{!videos && "LOADING..."}
					{videos?.map((video) => {
						return (
							<Link
								to={`${Routes.Watch}/${video.videoId}`}
								className="bg-cover bg-center transform desktop-hover:hover:scale-105 transition-all duration-200"
								style={{ paddingTop: "56.25%", backgroundImage: `url(${video.thumbnail})` }}
								key={video.videoId}
							/>
						);
					})}
				</div>
			</div>
			{liveVideoId && (
				<button
					className="pill glass-pane fixed bottom-16 left-1/2 transform -translate-x-1/2"
					onClick={() => {
						if (videoId) {
							history.push(Routes.Watch);
						} else {
							setIsModalOpen(false);
						}
					}}
				>
					LIVESTREAM
				</button>
			)}
		</div>
	);

	return (
		<>
			{!videoId && !liveVideoId && archiveContent}
			{(liveVideoId || videoId) && (
				<Fader isVisible={isModalOpen} className="absolute top-0 left-0 w-full h-full z-10 bg-white">
					{archiveContent}
				</Fader>
			)}
			{(videoId || liveVideoId) && (
				<div className="flex flex-col h-full overflow-auto mt-20 relative border-t border-black">
					<div className="flex-none">
						<Video youtubeId={videoId || (liveVideoId as string)} />
					</div>
					{!isModalOpen && (
						<button
							className="pill glass-pane fixed bottom-4 left-1/2 transform -translate-x-1/2"
							onClick={() => setIsModalOpen(true)}
						>
							VIDEO ARCHIVE
						</button>
					)}
					{!videoId ? (
						<div className="flex-auto min-h-0 overflow-visible">
							<iframe
								style={{ minHeight: 375, height: "100%" }}
								src={`https://www.youtube.com/live_chat?is_popout=1&embed_domain=${window.location.hostname}&v=5qap5aO4i9A`}
								title="chat"
								width="100%"
							></iframe>
						</div>
					) : (
						<div className="p-6">
							<div className="font-header-1">{currentVideo?.title}</div>
							<div className="mt-4">Date Uploaded: {moment(currentVideo?.time).format("MMMM, DD YYYY")}</div>
							<div className="mt-12 overflow-hidden uppercase">{currentVideo?.description}</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default Watch;


export const resizeImage = (file: File, size = 500) =>
  new Promise((resolve, reject) => {
    try {
      const imageFile = file
      const reader = new FileReader();

      reader.onload = rE => {
        const img = document.createElement('img');

        img.onload = () => {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          const realSize = Math.min(imgHeight, imgWidth);
          let width = size;
          let height = size;

          const xOffset = ((imgWidth - realSize) / 2) / realSize;
          const yOffset = ((imgHeight - realSize) / 2) / realSize;

          const canvas = document.createElement('canvas');
          canvas.width = size;
          canvas.height = size;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, (xOffset * imgWidth), (yOffset* imgHeight), realSize, realSize, 0, 0, width, height);

          canvas.toBlob(b =>
            resolve(
              new File([b as any], 'a-new-name.jpg', {
                type: 'image/jpeg',
              }),
            ),
          );
        };
        img.src = rE.target!.result as string;
      }
      reader.readAsDataURL(imageFile);
    } catch (e) {
      reject(e);
    }
  });

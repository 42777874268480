import { MEDIA_CONFIG } from "common";
import { FC } from "react";
import PageFade from "ui/PageFade";

import { useMediaQueries } from "@react-hook/media-query";

import DesktopGuestbook from "./Guestbook.desktop";
import MobileGuestbook from "./Guestbook.mobile";

type GuestbookProps = {
	profoundWords?: string
}

const Guestbook: FC<GuestbookProps> = ({profoundWords}) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);

	const Widget = matches.isMobile ? MobileGuestbook : DesktopGuestbook;

	return (
		<PageFade>
			<Widget profoundWords={profoundWords} />
		</PageFade>
	);
};

export default Guestbook;

import classNames from "classnames";
import { Routes } from "common";
import useLiveStream from "hooks/useLiveStream";
import useVideos from "hooks/useVideos";
import live from "images/live.svg";
import play from "images/play.svg";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "ui/UltraModal";

import { MinusIcon } from "@heroicons/react/solid";

const Watch: FC = (props) => {
	const liveVideoId = useLiveStream();
	const scrollRef = useRef<HTMLDivElement>(null);
	const archiveRef = useRef<HTMLDivElement>(null);
	const stageRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLIFrameElement>(null);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [scrollDown, setScrollDown] = useState(false);

	const { videoId } = useParams<{ videoId: string }>();
	const { videos } = useVideos();

	const currentVideo = videos?.find((video) => video.videoId === videoId);

	const scrollPage = () => {
		scrollRef?.current?.scrollTo({ top: !scrollDown ? scrollRef.current.offsetHeight : 0, behavior: "smooth" });
	};

	useEffect(() => {
		const cleanupRef = scrollRef.current;
		const onScroll = () => {
			if (!scrollRef.current) return;
			if (scrollRef.current.scrollTop === 0) archiveRef?.current?.scrollTo({ top: 0 });
			setScrollDown(scrollRef.current.scrollTop >= scrollRef.current.offsetHeight / 2);
		};
		scrollRef.current?.addEventListener("scroll", onScroll);
		return () => cleanupRef?.removeEventListener("scroll", onScroll);
	});

	useEffect(() => {
		const onResize = () => {
			if (!videoRef.current || !stageRef.current) return;
			let height = stageRef.current.offsetHeight;
			let width = height * (16 / 9);

			if (width > window.innerWidth) {
				width = stageRef.current.offsetWidth;
				height = width * (9 / 16);
			}

			videoRef.current.style.width = `${width - 98}px`;
			videoRef.current.style.height = `${height - 70}px`;
		};
		window.addEventListener("resize", onResize);
		onResize();
		return () => window.removeEventListener("resize", onResize);
	}, [liveVideoId]);

	return (
		<div className="flex flex-col w-screen h-screen overflow-hidden" ref={scrollRef}>
			{(videoId || liveVideoId) && (
				<div className="w-full h-screen relative flex flex-col items-center pt-28 lg:pt-40 flex-none">
					<div
						className="w-full flex justify-center items-center"
						style={{ height: "calc(100vh - 400px)" }}
						ref={stageRef}
					>
						<Modal
							style={{ width: "100%", maxWidth: "100%", maxHeight: "100%" }}
							className="relative"
							isOpen
							title={
								<div className="flex items-center">
									{videoId ? (
										<>
											<img src={play} alt="play" className="mr-4" loading="lazy" />
											HP oper.SYSTEM v1.1 VIDEO PLAYER
										</>
									) : (
										<>
											<img src={live} alt="live" className="mr-4" loading="lazy" />
											HP oper.SYSTEM v1.1 LIVESTREAM
										</>
									)}
								</div>
							}
						>
							<div className="p-4">
								<iframe
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									frameBorder="0"
									className="m-auto"
									src={`https://www.youtube.com/embed/${videoId || liveVideoId}?controls=0`}
									title="video embed"
									ref={videoRef}
								></iframe>
							</div>
							<div
								className={classNames("absolute -bottom-16 left-1/2 transform -translate-x-1/2", {
									"opacity-0": isModalOpen,
								})}
							>
								<button className="pill" onClick={() => setIsModalOpen(true)}>
									{!videoId ? "OPEN LIVE CHAT" : "INFORMATION"}
								</button>
							</div>
						</Modal>
					</div>
					<Modal
						isOpen={isModalOpen}
						style={{
							width: 400,
							height: 400,
						}}
						isSimple
						className="glass-pane absolute lg:right-20 bottom-36  left-1/2 lg:left-auto transform -translate-x-1/2 lg:translate-x-0 z-50"
						title={
							<div className="flex justify-end">
								<button className="flex items-center" onClick={() => setIsModalOpen(false)}>
									COLLAPSE <MinusIcon className="ml-4 w-4 h-4" />
								</button>
							</div>
						}
					>
						{!videoId ? (
							<iframe
								height="100%"
								src={`https://www.youtube.com/live_chat?is_popout=1&embed_domain=${window.location.hostname}&v=${liveVideoId}`}
								title="chat"
								width="100%"
							></iframe>
						) : (
							<div className="p-4">
								<div className="font-header-1 uppercase">{currentVideo?.title}</div>
								<div className="mt-6">Date Uploaded: {moment(currentVideo?.time).format("MMMM, DD YYYY")}</div>
								<div className="mt-12">{currentVideo?.description}</div>
							</div>
						)}
					</Modal>
					<button
						className="font-header-2 fixed left-1/2 bottom-6 lg:bottom-16 transform -translate-x-1/2 text-center cursor-pointer z-50"
						onClick={scrollPage}
					>
						{!scrollDown ? (
							<span className="pill glass-pane">VIDEO ARCHIVE</span>
						) : (
							<span className="pill glass-pane">LIVE STREAM</span>
						)}
					</button>
				</div>
			)}
			<div className="w-full h-screen relative flex-none overflow-auto" id="video-archive" ref={archiveRef}>
				<div className="py-36 lg:py-48 px-28 lg:px-48 grid grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10">
					{videos?.map((video) => {
						return (
							<Link
								to={`${Routes.Watch}/${video.videoId}`}
								className="bg-cover bg-center transform hover:scale-105 transition-all duration-750"
								style={{ paddingTop: "56.25%", backgroundImage: `url(${video.thumbnail})` }}
								key={video.videoId}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Watch;

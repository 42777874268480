import { MEDIA_CONFIG } from "common";
import useAbout from "hooks/useAbout";
import useCartMenu from "hooks/useCartMenu";
import useChat from "hooks/useChat";
import useMenu from "hooks/useMenu";
import { FC, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useMediaQueries } from "@react-hook/media-query";

import { GetMenuQuery, GlobalSettingModelTermsOfServiceField } from "lib/generated/sdk";
import classNames from "classnames";
import styles from "./Menu.module.css";
import checkIsExternal from "utils/isExternal";
import { Fader } from "./Fader";
import { NewsLetterModal } from "./NewsLetterModal";
import { TermsModal } from "./TermsModal";

export interface MenuProps {
	isLoading?: boolean;
	data: GetMenuQuery;
	terms: GlobalSettingModelTermsOfServiceField;
}

const MENU_ITEM_DELAY = 40;

const Menu: FC<MenuProps> = ({ isLoading, data, terms }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);

	const [chatIsOpen] = useChat(false);
	const [cartIsOpen, setIsCartOpen] = useCartMenu(false);
	const [aboutIsOpen, setIsAboutOpen] = useAbout(false);
	const [isMenuOpen, setIsMenuOpen] = useMenu(false);
	const [isNewsLetterOpen, setIsNewsLetterOpen] = useState(false);
	const [isTermsOpen, setIsTermsOpen] = useState(false);

	const openMenu = useCallback(() => {
		if (matches.isMobile) return;
		if (cartIsOpen) setIsCartOpen(false);
		if (aboutIsOpen) setIsAboutOpen(false);
		if (chatIsOpen) (document.querySelector("#sitegpt-chat-icon") as HTMLButtonElement).click();
		setIsMenuOpen(true);
	}, [aboutIsOpen, cartIsOpen, chatIsOpen, matches.isMobile, setIsAboutOpen, setIsCartOpen, setIsMenuOpen]);

	const menuConfig = useMemo(() => {
		if (!data) return;
		const baseItems = data.navigation.menu.map((item) => {
			const isExternal = checkIsExternal(item.slug);

			return {
				...item,
				isExternal,
			};
		});

		return matches.isMobile ? baseItems : baseItems.filter((item) => item?.showInMenu);
	}, [data, matches.isMobile]);

	return data ? (
		<>
			<NewsLetterModal isOpen={isNewsLetterOpen} setIsOpen={setIsNewsLetterOpen} />
			<TermsModal isOpen={isTermsOpen} setIsOpen={setIsTermsOpen} terms={terms} />
			<nav
				className="flex flex-col fixed left-6 md:left-8 top-20 -mt-2 md:mt-0 md:top-11 md:pt-5 lg:left-20 lg:top-28 pl-4 nav-zIndex"
				onMouseOver={openMenu}
				onMouseOut={() => {
					setIsMenuOpen(false);
				}}
			>
				<ul>
					{menuConfig.map((item, i) => {
						const mult = !isMenuOpen ? menuConfig.length - i : i;
						return (
							<Fader
								as="li"
								isVisible={isMenuOpen as boolean}
								removeChildren={false}
								key={item.title}
								duration={500}
								delay={mult * MENU_ITEM_DELAY}
							>
								{item.hasCustomFunctionality && (
									<div
										className={`${
											item.title.toUpperCase() === "NEWSLETTER" && "text-red-2"
										} cursor-pointer py-2 desktop-hover:hover:no-underline desktop-hover:hover:font-medium`}
										data-trigger="chat"
										onClick={() => {
											switch (item.title.toUpperCase()) {
												case "CHATBOT":
													return (document.querySelector("#sitegpt-chat-icon") as HTMLButtonElement).click();
												case "MY CART":
													return setIsCartOpen(true);
												case "ABOUT":
													return setIsAboutOpen(true);
												case "NEWSLETTER":
													return setIsNewsLetterOpen(true);
												case "TERMS":
													return setIsTermsOpen(true);
											}
										}}
									>
										{item.title.toUpperCase()}
									</div>
								)}

								{!item.hasCustomFunctionality && item.isExternal && (
									<a
										className="block py-2 desktop-hover:hover:no-underline desktop-hover:hover:font-medium"
										href={item.slug}
										key={item.title}
										rel="noreferrer"
										target="_blank"
									>
										{item.title.toUpperCase()}
									</a>
								)}

								{!item.hasCustomFunctionality && !item.isExternal && (
									<Link
										to={item.slug}
										className="block py-2 desktop-hover:hover:no-underline desktop-hover:hover:font-medium"
										key={item.title}
									>
										{item.title.toUpperCase()}
									</Link>
								)}
							</Fader>
						);
					})}
				</ul>
			</nav>
			<div
				className={classNames(styles["menu-background"], "fixed inset-0 pointer-events-none nav-background-zIndex", {
					[styles["visible"]]: isMenuOpen,
				})}
			/>
		</>
	) : null;
};

export default Menu;

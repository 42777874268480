import classNames from "classnames";
import { MEDIA_CONFIG, Routes } from "common";
import useCartMenu from "hooks/useCartMenu";
import { CartContext } from "pages/App";
import { FC } from "react";
import { Link } from "react-router-dom";

import { useMediaQueries } from "@react-hook/media-query";

const Cart: FC = () => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const [isCartOpen, setIsCartOpen] = useCartMenu(false);

	const getWidth = () => {
		if (matches.isDesktop) return 700;
		if (matches.isTablet) return 498;
		return "100%";
	};

	const content = (
		<CartContext.Consumer>
			{([cart, cartApi]) => (
				<div
					className={classNames(
						"md:border-l border-black h-full fixed top-0 right-0 z-50 flex flex-col transition-transform transform duration-500",
						{
							"translate-x-0": isCartOpen,
							"translate-x-full": !isCartOpen,
						}
					)}
					style={{
						width: getWidth(),
						height: matches.isMobile ? "calc(100% - 64px)" : undefined,
						top: matches.isMobile ? "64px" : undefined,
					}}
				>
					<div className="border-b border-black relative h-12 md:h-16 lg:h-32 flex-none relative bg-transparent md:bg-white">
						<div className="md:hidden font-header-2 absolute bottom-0 left-0 bg-white w-full py-2 px-6">MY CART</div>
						<button
							className="absolute right-6 md:right-8 lg:right-24 text-lg top-5 md:top-1/2 transform md:-translate-y-1/2 -mt-16 md:mt-0"
							onClick={() => setIsCartOpen(false)}
						>
							✕
						</button>
					</div>
					<div className="flex-auto min-h-0 overflow-y-auto bg-white">
						{!cart.cart ||
							!cart.cart?.lineItems ||
							(!cart.cart?.lineItems.length && <div className="p-6 md:p-8 lg:p-16">NO ITEMS IN CART</div>)}
						{cart.cart?.lineItems.map((item: any) => {
							return (
								<Link
									to={`${Routes.Product}/${item.variant.product.handle}`}
									className="flex items-center border-b border-black p-4"
									key={item.id}
									onClick={() => setIsCartOpen(false)}
								>
									<div className="w-20 h-20 md:w-40 md:h-40 bg-contain bg-center bg-no-repeat flex-none relative">
										<img src={item.variant.image.src} alt={item} />
										<button
											className="text-gray-1 font-caption md:hidden absolute -bottom-4 w-full text-center"
											onClick={(e) => {
												e.preventDefault()
												cartApi.removeItem(item.id)
											}}
										>
											REMOVE
										</button>
									</div>
									<div className="flex-auto pl-4" style={{ maxWidth: 320 }}>
										<div className="font-header-2">{item.title}</div>
										<div className="flex justify-between my-4">
											<span>PRICE:</span>
											<span className="font-header-2">{formatPrice(item.variant.price)}</span>
										</div>
										{item.variant.selectedOptions?.map((option: any) => {
											return (
												<div className="flex justify-between" key={option.name}>
													<span className="uppercase">{option.name}</span>
													<span>{option.value}</span>
												</div>
											);
										})}
										<div className="flex justify-between">
											<span>qty:</span>
											<span>{item.quantity}</span>
										</div>
										<button
											className="text-gray-1 font-body mt-4 md:inline-block hidden desktop-hover:hover:underline"
											onClick={(e) => {
												e.preventDefault()
												cartApi.removeItem(item.id)
											}}
										>
											REMOVE
										</button>
									</div>
								</Link>
							);
						})}
					</div>
					<div className="border-t border-black p-6 flex-none bg-white">
						<div className="m-auto" style={{ maxWidth: 300 }}>
							<div className="flex justify-between">
								<span>SUBTOTAL:</span>
								{/* Ignoring typescript since API type and API response don't overlap in typings */}
								{/* @ts-ignore */}
								<span className="font-header-2">{formatPrice(cart.cart?.subtotalPrice)}</span>
							</div>
							<div className="flex justify-between">
								<span>SHIPPING:</span>
								<span>Calculated at Checkout</span>
							</div>
						</div>
					</div>
					<div className="border-t border-black p-4 flex-none bg-white">
						<a href={(cart.cart as any)?.webUrl} className="btn w-full">
							CHECKOUT
						</a>
					</div>
				</div>
			)}
		</CartContext.Consumer>
	);

	if (matches.isMobile) {
		return isCartOpen ? content : null;
	}

	return content;
};

export interface Price {
	amount: string;
	currencyCode: string;
	type: any;
}

export const formatPrice = (priceObject: Price): string | null => {
	if (!priceObject) return null;
	const currencyCode = getCurrencySymbol(priceObject.currencyCode);
	return `${currencyCode} ${Number(priceObject.amount).toFixed(2)}`;
};

export function getCurrencySymbol(currencyCode: string) {
	switch (currencyCode.toUpperCase()) {
		case "USD":
			return "$";
		case "EUR":
			return "€";
		case "GBP":
			return "£";
		case "JPY":
			return "¥";
		default:
			return "";
	}
}

export default Cart;

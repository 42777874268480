import { MEDIA_CONFIG } from "common";
import useAbout from "hooks/useAbout";
import useCartMenu from "hooks/useCartMenu";
import useChat from "hooks/useChat";
import bot from "images/bot.png";
import logoText from "images/logo-text.svg";
import { CartContext } from "pages/App";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StructuredText } from "react-datocms";

import SecurityRobot from "images/robot.png";

import { useMediaQueries } from "@react-hook/media-query";

import Cart from "./Cart";
import styles from "./Chrome.module.css";
import { Fader } from "./Fader";
import Menu from "./Menu";
import NavLogo from "./NavLogo";
import Time from "./Time";
import Modal from "./UltraModal";
import classNames from "classnames";
import {
	GetMenuQuery,
	GetSettingsQuery,
	GlobalSettingModelOfflineMessageField,
	GlobalSettingModelTermsOfServiceField,
	Scalars,
} from "lib/generated/sdk";

import { useClickAway } from "react-use";

export interface ChromeProps {
	isLoading?: boolean;
	children: ReactNode;
	menu?: GetMenuQuery;
	about?: GetSettingsQuery["globalSetting"]["about"];
	appearOffline: boolean;
	offlineMessage?: GlobalSettingModelOfflineMessageField;
	terms?: GlobalSettingModelTermsOfServiceField;
}

function Chrome({ children, isLoading, menu, about, appearOffline, offlineMessage, terms }: ChromeProps) {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const [isAboutOpen, setIsAboutOpen] = useAbout(false);
	const [isChatOpen, setIsChatOpen] = useChat();
	const [isCartOpen, setIsCartOpen] = useCartMenu(false);
	const [showOfflineMessage, setShowOfflineMessage] = useState(false);
	const [newsLetterState, setNewsLetterState] = useState<"passive" | "active" | "sent">("passive");

	const formRef = useRef();

	useClickAway(formRef, () => {
		setNewsLetterState("passive");
	});

	useEffect(() => {
		if (appearOffline) {
			setTimeout(() => {
				setShowOfflineMessage(true);
			}, 1500);
		} else {
			return;
		}
	}, [appearOffline]);

	// @ts-ignore
	const bottomLinks = useMemo(() => {
		if (!menu) return;

		return menu.navigation.menu
			.filter(
				(item) =>
					item.title.toUpperCase() === "INSTAGRAM" ||
					item.title.toUpperCase() === "TIK TOK" ||
					item.title.toUpperCase() === "ABOUT"
			)
			.reduce((obj, item) => Object.assign(obj, { [item.title.toUpperCase()]: item }), {});
	}, [menu]);

	const setMutationObserver = useCallback(() => {
		const iframe = document.querySelector("#sitegpt-bot-ui") as HTMLIFrameElement;
		if (!iframe) return setTimeout(setMutationObserver, 500);
		const config = { attributes: true, childList: true, subtree: true };
		const observer = new MutationObserver((mutationList, observer) => {
			if (mutationList[0].attributeName === "style") {
				setIsChatOpen(iframe.style.display !== "none");
				iframe.classList[iframe.style.width === "100%" ? "add" : "remove"]("mobile");
			}
		});

		observer.observe(iframe, config);
	}, [setIsChatOpen]);

	useEffect(() => {
		setMutationObserver();
	}, [setMutationObserver]);

	return (
		<>
			<header>
				<NavLogo isLoading={isLoading || appearOffline} appearOffline={appearOffline} />
				<Menu data={menu} isLoading={isLoading || appearOffline} terms={terms} />
				<Time isLoading={isLoading} />
			</header>
			<div className="h-full">
				{/* {isChatOpen ? <Chat isLoading={isLoading || appearOffline} /> : null} */}

				{/* Loader text */}
				{/* Fades loading text out after page load quickly */}
				<Fader isVisible={isLoading || appearOffline} duration={500} className="fixed duration-500 nav-zIndex-logotext">
					<div className="fixed h-full w-full flex justify-center items-center select-none nav-zIndex-logotext">
						<div className="relative">
							<img src={logoText} alt="logo text" className={styles.logoText} loading="lazy" />
							<div className="font-header-2 absolute -bottom-20 left-1/2 transform -translate-x-1/2">
								<div className="whitespace-nowrap">LESS ENVIRONMENTALLY DESTRUCTIVE</div>
								<div className="flex justify-between items-center">
									{isLoading || !appearOffline ? (
										<div>
											Loading
											<span
												className={classNames("inline-block w-4 text-left animation-clip", styles["animation-clip"])}
											>
												...
											</span>
										</div>
									) : (
										<button
											onClick={() => {
												setShowOfflineMessage(true);
											}}
										>
											<span
												className={classNames(
													"inline-block text-left animation-clip-access-denied underline",
													styles["animation-clip-access-denied"]
												)}
											>
												Access Denied
											</span>
										</button>
									)}
									<div>HP oper.SYSTEM v1.1</div>
								</div>
							</div>
						</div>
					</div>
				</Fader>

				<Modal
					onClose={() => setShowOfflineMessage(false)}
					isCentered
					isOpen={appearOffline && showOfflineMessage}
					containerClassName="nav-zIndex-coming-soon"
					className="mx-6 md:mx-0"
					title={
						<div className="uppercase">
							<div>SECURITY PROMPT</div>
							COMING SOON
						</div>
					}
				>
					<div className="p-4">
						<div className="pt-8 md:pt-6 pb-8 flex justify-center items-center">
							<img
								alt="security robot"
								src={SecurityRobot}
								className={classNames("w-32 h-32 md:w-28 md:h-28 h-auto object-contain p", styles["lean-back"])}
							/>
						</div>
						<div className="pb-10 md:pb-6 text-center font-header-2">
							{offlineMessage?.value?.document?.children[0]?.children[0]?.value ? (
								<StructuredText data={offlineMessage as Scalars["JsonField"]} />
							) : (
								<p>This website is currently under construction. Please try again later</p>
							)}
						</div>
					</div>
				</Modal>

				{/* Fades bottom nav and initial view in on page load */}
				<Fader isVisible={!appearOffline && !isLoading} className="h-full w-full flex flex-col" removeChildren={false}>
					<header>
						<nav className="flex font-header-2 items-center fixed z-50 top-6 right-6 md:items-end md:right-auto md:bottom-6 lg:bottom-16 md:w-full justify-between md:px-8 lg:px-20 pointer-events-none">
							<div className="flex gap-8 items-center">
								{bottomLinks && (
									<>
										<a
											className="desktop-hover:hover:underline hidden md:inline-block pointer-events-auto py-2"
											href={bottomLinks["INSTAGRAM"].slug}
											rel="noreferrer nofollow"
											target="_blank"
										>
											{bottomLinks["INSTAGRAM"].title.toUpperCase()}
										</a>
										<a
											className="desktop-hover:hover:underline hidden md:inline-block pointer-events-auto py-2"
											href={bottomLinks["TIK TOK"].slug}
											rel="noreferrer nofollow"
											target="_blank"
										>
											{bottomLinks["TIK TOK"].title.toUpperCase()}
										</a>

										{newsLetterState === "active" ? (
											<form
												onSubmit={(e) => {
													e.preventDefault();
													const form = new FormData(e.target as HTMLFormElement);
													fetch("/api/signNewsletter", {
														method: "POST",
														body: JSON.stringify({
															email: form.get("email"),
														}),
													})
														.then((res) => res.json())
														.then((res) => {
															if (res.succes) {
																setNewsLetterState("sent");
															}
														});
												}}
												ref={formRef}
												className="hidden md:flex pointer-events-auto items-center relative"
											>
												<input
													required
													type="email"
													name="email"
													className="placeholder-black border-b border-black px-1 py-2"
													placeholder="ENTER EMAIL"
												/>

												<button className="bg-gray-1 px-4 py-2 ml-2 focus:bg-black focus:text-white" type="submit">
													SUBMIT
												</button>
											</form>
										) : (
											<button
												className="hidden md:inline-block pointer-events-auto"
												onClick={() => {
													setNewsLetterState("active");
												}}
											>
												<p className="text-red-2">{newsLetterState === "passive" ? "NEWSLETTER" : "THANKS!"}</p>
											</button>
										)}
									</>
								)}
							</div>

							<div className="flex items-center pointer-events-auto  py-2 ">
								{bottomLinks && (
									<button
										className="md:mr-8 desktop-hover:hover:underline cursor-pointer hidden md:inline-block"
										onClick={() => setIsAboutOpen(true)}
									>
										{bottomLinks["ABOUT"].title.toUpperCase()}
									</button>
								)}
								{!matches.isMobile || (!isChatOpen && !isCartOpen) ? (
									<button
										className="static top-6 right-6 desktop-hover:hover:underline cursor-pointer"
										onClick={() => setIsCartOpen(true)}
									>
										<CartContext.Consumer>
											{([cart]) => {
												const total = cart.cart?.lineItems.reduce(
													(total, lineItem) => total + lineItem.quantity || 1,
													0
												);
												return <>MY CART ({total | 0})</>;
											}}
										</CartContext.Consumer>
									</button>
								) : null}
							</div>
						</nav>
					</header>
					<main className="h-full">{children}</main>
				</Fader>

				<Modal
					title={<>ABOUT L.E.D. STUDIO</>}
					isCentered
					containerClassName={"transition-opacity duration-500"}
					isOpen={isAboutOpen as boolean}
					onClose={() => setIsAboutOpen(false)}
				>
					<div className="pt-6 px-4 md:px-16 pb-4 md:pb-12 [&_p]:mb-4 [&_a]:underline [&_p:last-of-type]:mb-0">
						<img src={bot} className="w-20 h-auto mb-6 mx-auto" alt="bot" loading="lazy" />
						{about && <StructuredText data={about.value} />}
					</div>
				</Modal>

				<Cart />
			</div>
		</>
	);
}

export default Chrome;

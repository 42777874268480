import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import classNames from "classnames";
import { MEDIA_CONFIG } from "common";
import useCartMenu from "hooks/useCartMenu";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Dock from "ui/Dock";
import PageFade from "ui/PageFade";

import { useMediaQueries } from "@react-hook/media-query";

import styles from "./Home.module.css";
import { GetHomePageQuery } from "lib/generated/sdk";

const INITIAL_SLIDE = 3;

interface HomeProps {
	data?: GetHomePageQuery;
}

const Home: FC<HomeProps> = ({ data }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);
	const [, setIsCartOpen] = useCartMenu(false);
	const [activeIndex, setActiveIndex] = useState(INITIAL_SLIDE);
	const ref = useRef(null);

	const getClickHandler = useCallback(
		(type: string) => {
			switch (type.toUpperCase()) {
				case "CHATBOT":
					return (document.querySelector('#sitegpt-chat-icon') as HTMLButtonElement).click()
				case "MY CART":
					return setIsCartOpen(true);
				default:
					return console.warn("No click handler found");
			}
		},
		[setIsCartOpen]
	);

	const items = useMemo(() => {
		return data.homepage.dock.map((item) => {
			return !item.hasCustomFunctionality ? (
				<Dock.Icon key={item.title} to={item.slug} image={item.icon.url} title={item.title.toUpperCase()} />
			) : (
				<Dock.Icon
					key={item.title}
					onClick={() => getClickHandler(item.title)}
					image={item.icon.url}
					title={item.title.toUpperCase()}
				/>
			);
		});
	}, [data.homepage.dock, getClickHandler]);

	return (
		<PageFade>
			<nav className={classNames("h-[100svh] flex justify-center items-center", styles.root)} ref={ref}>
				{matches.isMobile ? (
					<>
						<Swiper
							centeredSlides
							className="h-28 w-full md:h-56"
							freeModeMinimumVelocity={0.02}
							initialSlide={INITIAL_SLIDE}
							onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
							slidesPerView={4}
							spaceBetween={8}
						>
							{items.map((item) => {
								return <SwiperSlide key={item.key}>{item}</SwiperSlide>;
							})}
						</Swiper>
						<div className="absolute top-1/2 mt-24 left-0 w-full text-center transform -translate-y-1/2">
							{items[activeIndex].props.title}
						</div>
					</>
				) : (
					<Dock width={matches.isDesktop ? 740 : 640}>{[...items]}</Dock>
				)}
			</nav>
		</PageFade>
	);
};

export default Home;

import classNames from "classnames";
import { GuestbookEntry } from "common";
import useGuestbook from "hooks/useGuestbook";
import html2canvas from "html2canvas";
import glow from "images/glow.png";
import sleeve from "images/sleeve.png";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import IDCard from "ui/IDCard";
import IDInput from "ui/IDInput";
import { removeDuplicateObjectsFromArray } from "utils/removeDuplicateObjectsFromArray";

type GuestbookProps = {
	profoundWords?: string;
};

const Guestbook: FC<GuestbookProps> = ({ profoundWords }) => {
	const stage = useRef<HTMLDivElement>(null);
	const map = useRef<google.maps.Map>();
	const info = useRef<google.maps.InfoWindow>();
	const cardRef = useRef<any>(null);

	const [step, setStep] = useState(1);
	const [savedEntry, setSavedEntry] = useState<GuestbookEntry>();
	const [listEntry, setListEntry] = useState<GuestbookEntry>();
	const [isListOpen, setIsListOpen] = useState(false);
	const [downloadLink, setDownloadLink] = useState("");

	const { entries, refresh } = useGuestbook();
	const _entries = useMemo(() => removeDuplicateObjectsFromArray(entries), [entries]);

	const openMapCard = useCallback((entry: GuestbookEntry, marker: google.maps.Marker) => {
		if (!info.current) return;
		resetEntry();
		const content = ReactDOMServer.renderToString(
			<div style={{ width: 342, height: 188 }} className="ml-2">
				<IDCard
					value={entry}
					className="shadow-xl"
					style={{ transform: "scale(0.3275)", transformOrigin: "left top" }}
				/>
			</div>
		);
		info.current.setContent(content);
		info.current?.open({
			anchor: marker,
			map: map.current,
			shouldFocus: false,
		});
	}, []);

	const resetEntry = () => {
		setStep(1);
	};

	const startEntry = () => {
		closeListCard();
		setIsListOpen(false);
		info.current?.close();
		setStep(2);
	};

	const finishEntry = async (entry: GuestbookEntry) => {
		await refresh();
		setSavedEntry(entry);
		resetEntry();
	};

	const openList = () => {
		info.current?.close();
		resetEntry();
		setIsListOpen(true);
	};

	const closeList = () => {
		setIsListOpen(false);
	};

	const openListCard = (entry: GuestbookEntry) => {
		resetEntry();
		setListEntry(entry);
		document.body.classList.add("overflow-hidden");
	};

	const closeListCard = () => {
		setListEntry(undefined);
		document.body.classList.remove("overflow-hidden");
	};

	// Create map and info window
	useEffect(() => {
		if (!stage.current) return;
		map.current = new google.maps.Map(stage.current, {
			center: new google.maps.LatLng(40.7128, -40.006),
			disableDefaultUI: true,
			styles: [
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [{ visibility: "off" }],
				},
			],
			zoom: 2,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT,
			},
		});
		info.current = new google.maps.InfoWindow({ content: "" });
		map.current.addListener("click", () => {
			info.current?.close();
			setSavedEntry(undefined);
		});
	}, []);

	// Populate map with markers
	useEffect(() => {
		if (!map.current) return;
		_entries?.forEach((entry) => {
			const marker = new google.maps.Marker({
				icon: { url: entry.photo, scaledSize: new google.maps.Size(32, 32) },
				map: map.current,
				optimized: true,
				position: { lat: Number(entry.lat), lng: Number(entry.lng) },
			});
			marker.addListener("click", () => openMapCard(entry, marker));
		});
	}, [_entries, savedEntry, openMapCard]);

	useEffect(() => {
		async function cacheImage() {
			if (!cardRef.current) return;
			const canvas = await html2canvas(cardRef.current);
			canvas.toBlob((blob) => {
				const href = URL.createObjectURL(blob as Blob);
				setDownloadLink(href);
			});
		}
		cacheImage();
	}, [savedEntry]);

	return (
		<div
			className={classNames("flex flex-col h-full overflow-y-auto overflow-x-hidden mt-20 relative", {
				"!overflow-hidden": listEntry,
			})}
		>
			{savedEntry && (
				<div className="fixed p-10 bg-black top-0 fixed" style={{ left: -100000 }} ref={cardRef}>
					<img
						alt="sleeve"
						className="relative z-20"
						src={sleeve}
						style={{ width: 1112, height: "auto", maxWidth: "none" }}
						crossOrigin="anonymous"
						loading="lazy"
					/>
					<IDCard value={savedEntry} style={{ position: "absolute", top: 58, left: 58 }} />
				</div>
			)}

			<div className="w-full h-full md:border md:border-black" ref={stage} />

			<div
				className={classNames("absolute h-full w-full overflow-y-auto bg-white border-b border-t border-black", {
					hidden: !isListOpen,
					"!overflow-hidden": listEntry,
				})}
			>
				{_entries?.map((item, i) => {
					return (
						<div
							className="border-b last:border-b-0 border-black flex items-stretch"
							key={`${item.lat}-${item.lng}-${item.led}${item.name}`}
							onClick={() => openListCard(item)}
						>
							<img className="w-40" src={item.photo} alt={item.name} />
							<div className="flex flex-auto flex-col px-3 overflow-hidden">
								<div className="pt-3">
									<div className="w-full h-full line-clamp-2">{item.name}</div>
								</div>
								<div className="py-3">
									<div className="whitespace-nowrap truncate">{item.place}</div>
									<div className="whitespace-nowrap truncate">{moment(item.time).format("MM.DD.YYYY")}</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>

			{!savedEntry && (
				<div
					className={classNames(
						"fixed bottom-4 left-0 w-full px-6 flex justify-between duration-250 transition-opacity opacity-1",
						{ "opacity-0": step > 1 }
					)}
				>
					<div className="pill glass-pane" onClick={isListOpen ? closeList : openList}>
						{isListOpen ? "MAP VIEW" : "LIST VIEW"}
					</div>
					<div className="pill glass-pane" onClick={startEntry}>
						SIGN GUESTBOOK
					</div>
				</div>
			)}

			{listEntry && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<div className="m-auto" style={{ width: 326, height: 172 }}>
						<IDCard
							className="shadow-xl"
							onClose={closeListCard}
							style={{ transform: "scale(0.3275)", transformOrigin: "left top" }}
							value={listEntry}
						/>
					</div>
				</div>
			)}

			{step === 2 && (
				<div className="absolute h-full w-full glass-pane">
					<div className="w-full h-full overflow-y-auto p-6 pt-8">
						<div className="font-header-2">SIGN GUESTBOOK</div>
						<div className="mb-6">by creating your own L.E.D. Studio ID Card</div>
						<IDInput onSuccess={finishEntry} profoundWords={profoundWords} />
					</div>
					<button className="absolute text-lg right-6 top-6" onClick={resetEntry}>
						✕
					</button>
				</div>
			)}

			{savedEntry && (
				<div className="absolute h-full w-full glass-pane">
					<div className="w-full h-full overflow-hidden relative">
						<img
							fetchpriority="high"
							src={glow}
							className="absolute w-full h-auto left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-10"
							alt="glow"
							loading="eager"
						/>
						<div
							style={{ width: 326, height: 172 }}
							className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
						>
							<IDCard
								value={savedEntry}
								className="shadow-xl -mt-10"
								style={{ transform: "scale(0.3275)", transformOrigin: "left top" }}
							/>
						</div>

						<div className="w-full text-center absolute top-1/2 mt-16" data-trigger="id-card">
							<a href={downloadLink} className="pill mt-6 glass-pane" target="_blank" rel="noreferrer">
								DOWNLOAD
							</a>
						</div>
					</div>
					<button className="absolute text-lg right-6 top-6" onClick={() => setSavedEntry(undefined)}>
						✕
					</button>
				</div>
			)}
		</div>
	);
};

export default Guestbook;

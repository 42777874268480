import { MEDIA_CONFIG, Routes } from "common";
import studioDesktop from "images/studio-desktop.png";
import { FC } from "react";
import { Link } from "react-router-dom";
import PageFade from "ui/PageFade";
import { StructuredText } from "react-datocms";

import { useMediaQueries } from "@react-hook/media-query";
import { GetSettingsQuery } from "lib/generated/sdk";

interface ServiceCenterProps {
	data: GetSettingsQuery["globalSetting"]["serviceCenter"];
}

const ServiceCenter: FC<ServiceCenterProps> = ({ data }) => {
	const { matches } = useMediaQueries(MEDIA_CONFIG);

	return (
		<PageFade>
			<div className="w-full flex flex-col items-center justify-center">
				<div className="flex-none text-center px-6 md:px-0 flex flex-col items-center justify-center h-screen">
					<div
						className="font-studio-header mb-3 md:mb-2"
						style={{ fontSize: matches.isDesktop ? "19.42px" : matches.isTablet ? "24px" : "24px" }}
					>
						SERVICE CENTER
					</div>
					<div className="relative w-auto">
						<div className="font-body md:absolute md:top-24 lg:top-32 md:left-1/2 md:transform md:-translate-x-1/2 md:w-60 z-10">
							<p className="max-w-lg m-auto text-base text-sm">
								<StructuredText data={data.value} />
							</p>
						</div>
						<div>
							<img
								src={studioDesktop}
								style={{ width: matches.isDesktop ? "50rem" : matches.isTablet ? "674px" : "100%" }}
								alt="studio-c process"
								className="mt-11 md:mt-0 md:ml-12 lg:ml-16"
								loading="lazy"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="fixed bottom-8 md:bottom-16 w-full flex justify-between md:justify-center px-6 md:gap-4 z-40">
				<Link to={Routes.Projects} className="font-header-2 cursor-pointer pill glass-pane">
					PROJECTS
				</Link>
				<a href="mailto:hello@ledstudio.com" className="font-header-2 cursor-pointer pill glass-pane">
					CONTACT
				</a>
			</div>
		</PageFade>
	);
};

export default ServiceCenter;

import { animate } from "motion";
import { Children, FC, MouseEventHandler, ReactNode, createElement, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import checkIsExternal from "utils/isExternal";
export interface DockProps {
	children: ReactNode[];
	width: number;
}

const Dock = ({ children, width }: DockProps) => {
	const dock = useRef(null);
	const dockImages = useRef(null);

	let min = 85;
	let max = 100;
	let bound = min * Math.PI;

	const clientX = useRef(null);
	const [rafStarted, setRAFStarted] = useState(false);
	const rafId = useRef(0);

	useEffect(() => {
		if (!dock.current) return;
		let dockEl = dock.current;

		dockImages.current = dock.current.querySelectorAll("img");
		const mouseMove = (e) => {
			setRAFStarted(true);
			clientX.current = e.clientX;
		};

		dockEl.addEventListener("mousemove", mouseMove);
		return () => {
			dockEl?.removeEventListener("mousemove", mouseMove);
			setRAFStarted(false);
		};
	}, []);

	const ZOOM = 0.3;

	const draw = () => {
		if (!dock.current) return;
		if (!dockImages.current) return;

		const icons = dockImages.current;
		const rect = dock.current.getBoundingClientRect();
		const firstIconRect = icons[0].getBoundingClientRect();
		const iconWidth = firstIconRect.width;
		const offset = rect.left;
		const HIT_AREA = iconWidth;

		const pointer = clientX.current - offset;

		for (let i = 0; i < icons.length; i++) {
			let icon = icons[i];
			const diff = Math.abs(pointer - i * iconWidth - iconWidth / 2);
			const factor = 1 + (1 - Math.min(diff / HIT_AREA, 1)) * ZOOM;

			let distance = i * min + min / 2 - pointer;
			let x = 0;

			if (-bound < distance && distance < bound) {
				let rad = (distance / min) * 0.5;
				x = 2 * (max - min) * Math.sin(rad);
			} else {
				x = (-bound < distance ? 2 : -2) * (max - min);
			}

			icon.style.transform = `translate3d(${x * 0.2}px, 0, 0) scale(${factor})`;
		}

		rafId.current = requestAnimationFrame(draw);
	};

	useEffect(() => {
		if (rafStarted) {
			draw();
		} else {
			cancelAnimationFrame(rafId.current);
		}

		return () => {
			cancelAnimationFrame(rafId.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rafStarted]);

	function mouseLeave() {
		if (!dockImages.current) return;
		setRAFStarted(false);

		requestAnimationFrame(() => {
			animate(
				dockImages.current,
				{
					transform: `translate3d(0px, 0, 0) scale(1)`,
				},
				{
					duration: 0.3,
				}
			);
		});
	}

	return (
		<ul className="flex items-center relative" ref={dock} onMouseLeave={mouseLeave}>
			{Children.map(children, (child, i) => {
				return (
					<li
						className="dock-item h-56 w-28 flex items-center flex-none relative origin-[50%_-20%]"
						style={{
							transition: "all 150ms ease-out",
						}}
						key={i}
					>
						{child}
					</li>
				);
			})}
		</ul>
	);
};

export interface DockIconProps {
	image: string;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
	title: string;
	to?: string;
}

const Icon: FC<DockIconProps> = ({ onClick, to = "", image, title }) => {
	const isExternal = checkIsExternal(to);
	const link = isExternal ? { href: to, rel: "noopener noreferrer", target: "_blank" } : { to };
	return createElement(
		isExternal ? "a" : onClick ? "div" : Link,
		// @ts-ignore
		{
			className: "cursor-pointer block w-full h-full relative text-transparent hover:text-black",
			onClick,
			...link,
		},
		<>
			<img
				src={image}
				alt={title}
				className="absolute w-full p-3 h-full object-contain object-center pointer-events-none"
				fetchpriority="high"
				loading="eager"
			/>
			<div className="absolute w-full bottom-0 text-center font-header-2 whitespace-nowrap hidden md:block">
				{title}
			</div>
		</>
	);
};

Dock.Icon = Icon;

export default Dock;

import classNames from "classnames";
import { Routes } from "common";
import { FC, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import styles from "./Project.module.css";
import { GetProjectsQuery } from "lib/generated/sdk";
import { StructuredText } from "react-datocms/structured-text";
import { render } from "datocms-structured-text-to-plain-text";

const MAX_LENGTH = 400;
export interface Img {
	projectImage: {
		url: string;
	};
	orientation: boolean;
}

const Project: FC<{ data: GetProjectsQuery }> = ({ data }) => {
	const history = useHistory();
	const params = useParams<{ handle: string }>();
	const project = data?.allProjects?.find((proj) => proj.link === params.handle);

	const [viewMore, setViewMore] = useState(false);

	const txtString = render(project.excerpt.value);
	const txtLength = txtString.length;

	const hasMore = txtLength > MAX_LENGTH;
	const shortText = hasMore ? `${txtString.slice(0, MAX_LENGTH)}...` : txtString;

	const orderedImages = useMemo<Array<Img | Img[]>>(() => {
		const resultArray = [];
		let group = [];

		for (const item of project.projectImages) {
			if (item.orientation) {
				//Horizontal
				resultArray.push(item);
			} else {
				group.push(item);
				if (group.length === 2) {
					resultArray.push(group);
					group = [];
				}
			}
		}

		if (group.length && group.length < 2) {
			resultArray.push(group);
		}

		return resultArray;
	}, [project.projectImages]);

	return (
		<div className={classNames("h-full w-full flex flex-col", styles.root)}>
			<div className="border border-black flex flex-col overflow-hidden fixed lg:top-48 md:bottom-40 lg:left-20 lg:right-20 md:top-36 md:left-8 md:right-8">
				<div className="flex justify-between items-center border-b border-black p-4 font-header-2">
					<div>{project.title}</div>
					<button onClick={() => history.push(Routes.Projects, { skipTransition: true })}>- COLLAPSE</button>
				</div>
				<div className="flex-auto flex min-h-0">
					<div className="w-1/3 lg:w-1/4 px-4 pt-4 overflow-y-auto pb-4 flex-none">
						<div className="font-body">{viewMore ? <StructuredText data={project.excerpt.value} /> : shortText}</div>
						{hasMore && (
							<button className="mt-6 font-header-2 hover:underline" onClick={() => setViewMore(!viewMore)}>
								{viewMore ? <>- VIEW LESS</> : <>+ VIEW MORE</>}
							</button>
						)}
					</div>
					<div className="flex-auto p-4 border-l border-black overflow-y-auto min-h-0 studio-c-content">
						<p>
							<img fetchpriority="high" src={project.thumbnail.url} alt="featured" loading="eager" />
						</p>
						<div>
							{orderedImages.map((image) => {
								return Array.isArray(image) ? (
									<p key={image[0].projectImage.url}>
										<img src={image[0].projectImage.url} alt="" />
										{image.length > 1 ? <img src={image[1].projectImage.url} alt="" /> : null}
									</p>
								) : (
									<p key={image.projectImage.url}>
										<img src={image.projectImage.url} alt="" />
									</p>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="fixed left-1/2 bottom-16 transform -translate-x-1/2 z-50">
				<Link
					to={{ pathname: Routes.Projects, state: { skipTransition: true } }}
					className="font-header-2 cursor-pointer pill glass-pane"
				>
					BACK TO PROJECTS
				</Link>
			</div>
		</div>
	);
};

export default Project;

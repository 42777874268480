import { FC } from "react";

export interface VideoProps {
	youtubeId: string;
	responsive?: boolean;
}

const Video: FC<VideoProps> = ({ youtubeId, responsive }) => {
	if (responsive) {
		return (
			<iframe
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				className="w-full h-full block"
				frameBorder="0"
				src={`https://www.youtube.com/embed/${youtubeId}?controls=0`}
				title="video embed"
			></iframe>
		);
	}

	return (
		<div className="relative h-0" style={{ paddingBottom: "56.25%" /* 16:9 */ }}>
			<iframe
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				className="absolute top-0 left-0 w-full h-full"
				frameBorder="0"
				src={`https://www.youtube.com/embed/${youtubeId}?controls=0`}
				title="video embed"
			></iframe>
		</div>
	);
};

export default Video;
